import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormOptionGroup,
  Grid,
  Headline,
  Type,
  Utility,
} from "mesh-component-library";
import { useLocale } from "../../contexts/LocaleContext";
import CarIllustrationFuelTriage from "../../icon-library/src/icons/CarIllustrationFuelTriage";
import { useScreenBreakpoint } from "../../hooks/useScreenBreakpoint";
import { getCommonBenefits, getServiceInfo } from "../../selector";
import { sendFuelTypeToJacada } from "../../jacada/sendJacadaEventData";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import _lowerCase from "lodash/lowerCase";
import _cloneDeep from "lodash/cloneDeep";
import { FUEL_DELIVERY, FUEL_SERVICE_ID, GAS } from "../../app-consts/appConstants";
import titleCase from "../../utils/titleCase";
import { FuelAlert } from "../FuelAlert";
import isEmpty from "lodash/isEmpty";

const FuelTriage = ({ updateSoftServiceDetails, ...restProps }) => {
  const getTranslatedText = useLocale();
  const screenBreakpoint = useScreenBreakpoint();
  const commonBenefits = useSelector(getCommonBenefits);
  const serviceInfo = useSelector(getServiceInfo);

  const [fuelType, setFuelType] = useState(
    commonBenefits?.vehicles?.length === 1
      ? titleCase(commonBenefits.vehicles[0]?.fuelType)
      : ""
  );

  const selectFuelType = (event) => {
    setFuelType(event.currentTarget.value);
    sendFuelTypeToJacada(event);
  };

  const isButtonEnabled = () => {
    if (serviceInfo.id === FUEL_SERVICE_ID) {
      return !!fuelType;
    }
    return true;
  };

  const onContinue = () => {
    updateSoftServiceDetails(false, FUEL_DELIVERY, {
      fuelType,
    });
  };

  const fuelServiceCoverages = commonBenefits?.services?.find((service) => service.type === GAS)?.coverages;

  return (
    <Grid verticalAlign="middle">
      <Grid.Column>
        <CarIllustrationFuelTriage isMobile={screenBreakpoint === "sm"} />
      </Grid.Column>
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
          }}
        >
          <Headline
            title={
              <Type
                variant="darker"
                align="left"
                size={screenBreakpoint === "sm" ? "6xl" : "3xl"}
                weight="semibold"
              >
                {getTranslatedText(
                  TRANSLATION_CONSTANTS.WHAT_IS_YOUR_FUEL_TYPE
                )}
              </Type>
            }
          />
        </Utility.Div>
      </Grid.Column>
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
          }}
        >
          <FormOptionGroup
            id="helpmechoose"
            type="radio"
            required={true}
            options={[
              {
                id: "helpmechoose1",
                name: "helpmechoose",
                text: getTranslatedText(TRANSLATION_CONSTANTS.UNLEADED),
                value: "Unleaded",
                checked: fuelType === "Unleaded",
                onChange: (event) => {
                  selectFuelType(event);
                },
              },
              {
                id: "helpmechoose2",
                name: "helpmechoose",
                text: getTranslatedText(TRANSLATION_CONSTANTS.DIESEL),
                value: "Diesel",
                checked: fuelType === "Diesel",
                onChange: (event) => {
                  selectFuelType(event);
                },
              },
            ]}
          />
        </Utility.Div>
      </Grid.Column>
      {commonBenefits.experience?.fuelCostCovered && !isEmpty(fuelServiceCoverages) && (
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Utility.Div
            utils={{
              hrLeft: "md",
              hrRight: "md",
            }}
          >
            <FuelAlert
              fuelServiceCoverages={fuelServiceCoverages}
            />
          </Utility.Div>
        </Grid.Column>
      )}
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
        utils={{
          vrTop: "md",
        }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
          }}
        >
          <Button
            hasUpgrade
            shape="pill"
            size="lg"
            onClick={onContinue}
            disabled={!isButtonEnabled()}
            isBlock
          >
            {_lowerCase(getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE))}
          </Button>
        </Utility.Div>
      </Grid.Column>
    </Grid>
  );
};

export default FuelTriage;
