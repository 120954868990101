export const REQUESTED_PARTNER_DETAILS = "REQUESTED_PARTNER_DETAILS";
export const REQUESTED_PARTNER_DETAILS_SUCCESS = "REQUESTED_PARTNER_DETAILS_SUCCESS";
export const REQUESTED_PARTNER_DETAILS_FAILED = "REQUESTED_PARTNER_DETAILS_FAILED";
export const UPDATE_PARTNER_DETAILS_EXPERIENCE = "UPDATE_PARTNER_DETAILS_EXPERIENCE";
export const UPDATE_PARTNER_DETAILS_PHONE = "UPDATE_PARTNER_DETAILS_PHONE";
export const UPDATE_PARTNER_DETAILS_PARTNER_CODE = "UPDATE_PARTNER_DETAILS_PARTNER_CODE";
export const UPDATE_PARTNER_DETAILS_DISPLAY_CODE = "UPDATE_PARTNER_DETAILS_DISPLAY_CODE";
export const UPDATE_PARTNER_DETAILS_ROUTE = "UPDATE_PARTNER_DETAILS_ROUTE";
export const REFRESH_REDUCER_PARTNER_DETAILS = "REFRESH_REDUCER_PARTNER_DETAILS";

export function requestPartnerDetails(partnerCode){
    return { type: REQUESTED_PARTNER_DETAILS, partnerCode };
}

export function requestPartnerDetailsSuccess(data){
    return { type: REQUESTED_PARTNER_DETAILS_SUCCESS, payload: data};
}

export function requestPartnerDetailsFailed(){
    return { type: REQUESTED_PARTNER_DETAILS_FAILED};
}

export function updatePartnerDetailsExperience(data){
  return { type: UPDATE_PARTNER_DETAILS_EXPERIENCE, payload: data};
}

export function updatePartnerDetailsPhone(data){
  return { type: UPDATE_PARTNER_DETAILS_PHONE, payload: data};
}

export function updatePartnerDetailsPartnerCode(data){
  return { type: UPDATE_PARTNER_DETAILS_PARTNER_CODE, payload: data};
}

export function updatePartnerDetailsDisplayCode(data){
  return { type: UPDATE_PARTNER_DETAILS_DISPLAY_CODE, payload: data};
}

export function updatePartnerDetailsRoute(data){
  return { type: UPDATE_PARTNER_DETAILS_ROUTE, payload: data};
}

export function refreshReducerPartnerDetails(){
  return { type: REFRESH_REDUCER_PARTNER_DETAILS};
}
