var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import Form from "../form";
import FormField from "../form-field";
import CONSTANTS, { ZIP_LENGTH_REGEX } from "../../app-consts/appConstants";
import { regexUtil } from "../../utils/regexUtil";
import LookUpFormHeader from './Components/LookUpFormHeader';
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { Button } from 'mesh-component-library';
var FirstNameLastNameZipLookup = function (_a) {
    var partnerDetails = _a.partnerDetails, dispatchCommonBenefitsLookup = _a.dispatchCommonBenefitsLookup, showModal = _a.showModal, closeModal = _a.closeModal;
    var displayCode = partnerDetails.displayCode;
    var _b = useState({ value: '' }), firstName = _b[0], setFirstName = _b[1];
    var _c = useState({ value: '' }), lastName = _c[0], setLastName = _c[1];
    var _d = useState({ value: '', hasError: false }), zip = _d[0], setZip = _d[1];
    var getTranslatedText = useLocale();
    var continueClick = function () {
        var user = {
            details: {
                firstName: firstName.value,
                lastName: lastName.value,
                zip: zip.value
            },
            lookupType: 'firstNameLastNameZip',
            phoneLookup: false,
        };
        dispatchCommonBenefitsLookup(user);
        localStorage.setItem('firstName', firstName.value);
        localStorage.setItem('lastName', lastName.value);
        localStorage.setItem('zip', zip.value);
    };
    var handleZipBackspace = function (e) {
        if (e.keyCode !== CONSTANTS.DELETE && e.keyCode !== CONSTANTS.BACKSPACE && e.target.value !== undefined && e.target.value.toString().length >= e.target.maxLength) {
            e.preventDefault();
        }
        else {
            setZip(__assign(__assign({}, zip), { hasError: true }));
        }
    };
    var handleZipChange = function (event) {
        var value = event.target.value;
        var isValid = regexUtil(ZIP_LENGTH_REGEX, value);
        setZip({
            value: value,
            hasError: !isValid
        });
    };
    var validateCompleteForm = function () {
        return !(firstName.value &&
            lastName.value &&
            (!zip.hasError && zip.value.length === 5));
    };
    var onSubmit = function () {
        var content = React.createElement("div", null,
            React.createElement("p", null,
                React.createElement("span", null,
                    getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME),
                    ":"),
                " ",
                firstName.value),
            React.createElement("p", null,
                React.createElement("span", null,
                    getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME),
                    ":"),
                " ",
                lastName.value),
            React.createElement("p", null,
                React.createElement("span", null,
                    getTranslatedText(TRANSLATION_CONSTANTS.ZIP),
                    ":"),
                " ",
                zip.value));
        var options = {
            titleHtml: content,
            yesNoSelected: yesNoSelected,
        };
        showModal(options);
    };
    var yesNoSelected = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var value = _b.target.value;
        return __generator(this, function (_c) {
            closeModal();
            if (value === "yes") {
                continueClick();
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(LookUpFormHeader, { titleText: getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS), id: "we-need-some-info-to-find-your-benefits-name-zip" }),
        React.createElement(Form, { id: "firstNameLastNameZipLookupForm", className: "c-container-lookup__form", validateOnBlur: true },
            React.createElement(FormField, { errorText: getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME), id: "firstName", label: getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME), name: "firstName", className: "firstName", value: firstName.value, onChange: function (event) { return setFirstName({ value: event.target.value }); }, infoPopup: getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_ACCOUNT), required: true }),
            React.createElement(FormField, { errorText: getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME), id: "lastName", label: getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME), name: "lastName", className: "lastName", value: lastName.value, onChange: function (event) { return setLastName({ value: event.target.value }); }, required: true }),
            React.createElement(FormField, { errorText: getTranslatedText(TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE), id: "zipCode", label: getTranslatedText(TRANSLATION_CONSTANTS.ZIP_CODE), type: "number", name: "zipCode", className: "zipCode", maxLength: 5, value: zip.value, onKeyDown: handleZipBackspace, onChange: handleZipChange, meta: getTranslatedText(TRANSLATION_CONSTANTS.AS_APPEARS_ACCOUNT), hasError: zip.hasError, required: true }),
            React.createElement(Button, { size: 'lg', hasUpgrade: true, id: "fnlnzip-lookup-continue", "data-testid": "first-last-zip-continue", disabled: validateCompleteForm(), onClick: onSubmit, utils: {
                    fullWidth: true
                } }, getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)))));
};
export default FirstNameLastNameZipLookup;
