import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";

export const getDisplayMessage = (towEnabled, serviceName) => {
    const content = {
        "Lockout": {
            title: TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS,
            message: towEnabled ? TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED : TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN
        },
        "Lockout-Trunk": {
            title: TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS,
            message: TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH
        },
        "Tire Change": {
            title: TRANSLATION_CONSTANTS.WITHOUT_A_SPARE_TOW_VEHICLE,
            message: TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED,
        },
        'Jump Start': {
            title: TRANSLATION_CONSTANTS.BATTER_MOST_LIKELY_PROBLEM,
            message: towEnabled ? TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED : TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE,
        },
        'Default': {
            title: TRANSLATION_CONSTANTS.DEFAULT,
            message: TRANSLATION_CONSTANTS.DEFAULT,
        }
    }
    return content[serviceName] || content["Default"]
}