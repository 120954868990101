import React from 'react';

export const CarIcon = () => 
<svg id="car-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
<defs />
<g fill="none" stroke="none" strokeWidth="1">
  <g className="carAnimation">
    <g className="Car" fill="#B9C6D3">
      <path d="M6.17569546,7.92534786 C5.68374817,7.81795238 4.56734993,7.76242819 4.56734993,7.76242819 C4.36603221,7.75293063 4.20058565,7.91000565 4.20058565,8.11091555 L4.51098097,9.90887656 C4.51098097,10.1105171 4.67642753,10.280012 4.87847731,10.2865872 L9.58931186,10.4378176 C9.79136164,10.4436622 9.85065886,10.3216551 9.72108346,10.1667718 C9.72108346,10.1667718 8.22547584,8.3731943 6.17569546,7.92534786 L6.17569546,7.92534786 Z M14.1500732,16.5768937 C14.9641288,16.5768937 15.6259151,15.916448 15.6259151,15.1040414 C15.6259151,14.2916348 14.9641288,13.6304586 14.1500732,13.6304586 C13.3352855,13.6304586 12.6734993,14.2916348 12.6734993,15.1040414 C12.6734993,15.916448 13.3352855,16.5768937 14.1500732,16.5768937 L14.1500732,16.5768937 Z M3.261347,10.2361771 C3.46339678,10.2434829 3.62884334,10.0849467 3.62884334,9.88257563 L3.31844802,8.08242287 C3.31844802,7.88078239 3.15300146,7.72370737 2.95095168,7.73320493 C2.95095168,7.73320493 1.60834553,7.79822668 0.319180088,8.24388138 C0.161054173,8.85464749 0.0512445095,9.48294756 0,10.1295122 L3.261347,10.2361771 Z M22,10.9996347 C22,17.0751503 17.0658858,22 10.9780381,22 C6.63103953,22 2.88286969,19.4816857 1.0885798,15.8324312 L11.8960469,15.8324312 C12.2064422,16.7843788 13.0944363,17.4791618 14.1500732,17.4791618 C15.2005857,17.4791618 16.0841874,16.7916847 16.3989751,15.8477734 C17.0710102,15.8148972 18.4121523,15.6921595 18.7247438,15.2370073 C19.375549,14.2894431 19.295022,12.4133099 18.602489,11.2604523 C18.3565154,10.8513267 17.0966325,10.3917909 15.4150805,10.0396506 C13.7335286,9.68751038 11.8345534,9.57938432 11.8345534,9.57938432 C11.8345534,9.57938432 9.42020498,7.81941354 8.49780381,7.3321157 C7.57540264,6.84481785 6.41434846,6.27131139 3.15446559,6.33048849 C2.33308931,6.3458307 1.58052709,6.44811211 0.877745242,6.60372597 C2.57833089,2.71849367 6.45827233,0 10.9780381,0 C17.0658858,0 22,4.92484973 22,10.9996347 L22,10.9996347 Z" />
    </g>
    <g className="Tick" fill="#95D600">
      <path d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
    </g>
  </g>
</g>
</svg>

