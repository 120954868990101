import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  requestPartnerDetails,
  commonBenefitsStart,
  benefitsLookupStart,
  updateContactNumber,
  updateCurrentLocation
} from '../../action';
import {generatePath} from './../../utilities/RedirectUtils';
import CarAnn from '../../components/svg/CarAnn';
import Spinner from "../../components/spinner";
import translatePartnerCode from '../../utils/translatePartnerCode';
import trimmedLengthIsTen from '../../utils/phoneLength';
import {sendJacadaRequest} from '../../api';
import {datadogRum} from "@datadog/browser-rum";
import CONSTANTS, {
  ACTIVE_MEMBERSHIP_STATUSES
} from '../../app-consts/appConstants';

export class Lookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true
    }
    this.showSpinnerModal = this.showSpinnerModal.bind(this);
    this.benefitLookup = this.benefitLookup.bind(this);
    this.partnerCode = translatePartnerCode(props.partnerDetails.displayCode || '');
    this.values = {};
    this.benefitsLookupStarted = false;
  }

  UNSAFE_componentWillMount() {
    if ("member" === this.props.partnerDetails.route && null === localStorage.getItem('vin')) {
      let urlParams = new URLSearchParams(window.location.search);
      const vinParameter = urlParams.get('VIN') || urlParams.get('vin');
      const odoParameter = urlParams.get('ODO') || urlParams.get('odo');
      const arsTokenParameter = urlParams.get('ARS_TOKEN') || urlParams.get('ars_token');
      this.values.vin = vinParameter;
      this.values.odo = odoParameter;
      this.values.ars_token = arsTokenParameter;
      this.values.fname = urlParams.get('FNAME');
      this.values.lname = urlParams.get('LNAME');
      this.values.email = urlParams.get('EMAIL');
      this.values.phone = urlParams.get('PHONE');
      this.values.conversationId = urlParams.get('conversationId');
      vinParameter && localStorage.setItem('vin', vinParameter);
      odoParameter && localStorage.setItem('mileage', odoParameter);
      arsTokenParameter && localStorage.setItem('ars_token', arsTokenParameter);
      urlParams.get('conversationId') && localStorage.setItem('conversationId', urlParams.get('conversationId'));
      urlParams.get('FNAME') && localStorage.setItem('firstName', urlParams.get('FNAME'));
      urlParams.get('LNAME') && localStorage.setItem('lastName', urlParams.get('LNAME'));
      urlParams.get('PHONE') && this.props.updateContactNumber(urlParams.get('PHONE'));
      urlParams.get('EMAIL') && localStorage.setItem('email', urlParams.get('EMAIL'));
    } else {
      this.values.vin = localStorage.getItem('vin');
      this.values.odo = localStorage.getItem('mileage');
      this.values.fname = localStorage.getItem('firstName');
      this.values.lname = localStorage.getItem('lastName');
      this.values.email = localStorage.getItem('email');
      this.values.phone = this.props.serviceRequestPayload.serviceRequest.contactNumber;
      this.values.ars_token = localStorage.getItem('accessToken');
    }
  }

  componentDidMount() {
    document.title = 'Lookup | Roadside Assistance';
    window.scrollTo(1, 1);

    if (localStorage.getItem('rescueCancel') && localStorage.getItem('rescueCancel') !== 'YES' && this.props.partnerDetails.route !== 'member') {
      localStorage.clear();
    }
    this.showSpinnerModal();
    this.props.requestPartnerDetails(this.partnerCode);
    if (Object.keys(this.values).length !== 0) {
      this.values.vin && localStorage.setItem('vin', this.values.vin);
      this.values.odo && localStorage.setItem('mileage', this.values.odo);
      this.values.fname && localStorage.setItem('firstName', this.values.fname);
      this.values.lname && localStorage.setItem('lastName', this.values.lname);
      this.values.phone && this.props.updateContactNumber(this.values.phone);
      this.values.email && localStorage.setItem('email', this.values.email);
    }
    datadogRum.addAction('Lookup component mounts')
  }

  componentDidUpdate() {
    const {commonBenefits, partnerDetails} = this.props;
    const values = new URLSearchParams(window.location.search);
    const isConversationOrJobIdChanged = values.get('conversationId') !== localStorage.getItem('conversationId') || values.get('jobId') !== localStorage.getItem('jobId');
    if(partnerDetails.experience && (commonBenefits.loading === undefined || isConversationOrJobIdChanged) && !this.benefitsLookupStarted) {
      this.benefitLookup();
      this.benefitsLookupStarted = true;
    }
    let commonBenefitsIsLoaded = commonBenefits.loading === false
    if(commonBenefitsIsLoaded){
      if ((commonBenefits.noBenefits || commonBenefits.error) && !isConversationOrJobIdChanged) {
        if (commonBenefits.errorMessage === CONSTANTS.OUT_OF_CLAIMS_MESSAGE || commonBenefits.errorMessage === CONSTANTS.INVALID_OAUTH_TOKEN) {
          if (partnerDetails.experience.ppuEnabled) {
            this.props.history.push(generatePath( 'home'));
          } else {
            this.props.history.push(generatePath( 'servicetransition'));
          }
        } else if (!partnerDetails.experience.ppuEnabled && !partnerDetails.experience.benefitsEnabled && partnerDetails.experience.serviceSelectionEnabled) {
          this.props.history.push(generatePath( 'home'));
        } else {
          this.props.history.push(generatePath( 'memberlookup'));
        }
      } else if(commonBenefits.benefits){
        if (ACTIVE_MEMBERSHIP_STATUSES.includes(commonBenefits.status.toUpperCase())) {
          localStorage.setItem('memberId', commonBenefits.memberId);

          this.props.history.push(generatePath( 'home'));
        }
      }
    }
  }

  showSpinnerModal() {
    this.setState({showSpinner: true});
  }

  benefitLookup() {
    const values = new URLSearchParams(window.location.search);
    let user = {};
    let phone;
    const { partnerDetails, commonBenefitsStart, benefitsLookupStart } = this.props;
    const { appid, route, experience, promoCode, enhancedAuthPartner, omniStreamlined = "" } = partnerDetails;
    const { aniLookupEnabled, aniLookupSessionType } = experience;
    let appId = appid;
    if (values.get('ANI')) {
      phone = values.get('ANI').replace(/\s/g, "");
      if (phone && phone[0] === "1") {
        phone = phone.replace(phone[0], "");
      }
      if (trimmedLengthIsTen(phone)) {
        this.props.updateContactNumber(phone);
      }
    }

    if (values.get('jobId')) {
      localStorage.setItem('jobId', values.get('jobId'));
    }

    if (values.get('conversationId')) {
      localStorage.setItem('conversationId', values.get('conversationId'));
    }

    const isOmniAssist = route === 'OmniAssist';

    if (isOmniAssist) {
      datadogRum.addAction("OmniAssist ANI Lookup")
      localStorage.setItem('partnerCode', this.partnerCode);
      user.appId = appId;
      if (!values.get('jobId')) {
        datadogRum.addError(new Error('jobId query param missing from OmniAssist link.'), {
          params: values.toString()
        });
      }
      if (!values.get('conversationId')) {
        datadogRum.addError(new Error('conversationId query param missing from OmniAssist link.'), {
          params: values.toString()
        });
      }
      if (phone && aniLookupEnabled) {
        if (trimmedLengthIsTen(phone)) {
          user.memberId = phone;
          user.phoneLookup = true;
          user.sessionType = aniLookupSessionType;
          user.lookupType = CONSTANTS.AUTO_PHONE_LOOKUP
          this.phoneLookup = true;
          localStorage.setItem('memberId', phone);

          if(!!omniStreamlined) {
            benefitsLookupStart(user);
          } else {
            commonBenefitsStart(user);
          }

          if (values.get('jobId')) {
            let eventsData = [];
            eventsData.push(
              {
                'name': 'original_ani_nbr',
                'value': phone
              },
              {
                'name': 'disblmnt_prmotn_cd',
                'value': promoCode
              },
              {
                'name': 'partnr_cd',
                'value': this.partnerCode
              },
              {
                'name': 'job_id',
                'value': values.get('jobId')
              }
              ,
              {
                'name': 'disbl_latitd_nbr',
                'value': 0
              },
              {
                'name': 'disbl_longitd_nbr',
                'value': 0
              }
              );

            let jacadaRequestData = {
              'externalSystemId': localStorage.getItem('conversationId'),
              'events': eventsData
            }
            sendJacadaRequest(jacadaRequestData);
          }
          //Don't redirect since looking up member benefits by phone
          return;
        } else {
          datadogRum.addError(new Error('ANI in unexpected format from OmniAssist link.'), {
            params: values.toString()
          });
        }
      }
      if(this.props.partnerDetails.partnerCode !== undefined &&
        this.props.partnerDetails.partnerCode === window.location.pathname.split('/')[2] &&
        this.props.partnerDetails.partnerCode !== this.props.partnerDetails.displayCode) {
        this.props.history.push(generatePath('memberlookup', this.props.partnerDetails.displayCode))
      }
    }

    const isEnhanceAuth = enhancedAuthPartner && values.get('session')

    if (isEnhanceAuth) {
      datadogRum.addAction("Enhanced Auth Benefits Lookup")
      user.lookupType = 'appointmentNumber'
      user.searchCallId = values.get('session')
      localStorage.setItem('usageId', values.get('usage'))
      benefitsLookupStart(user);
    }

    const vinOdoParameters = !!values.get('vin') && !!values.get('odo');

    if(values.get('ars_token') && vinOdoParameters) {
      datadogRum.addAction("Auto Vin Odo Lookup with ars_token")
      user.lookupType = 'autoVinOdoLookup';
      user.vin = values.get('vin');
      user.mileage = values.get('odo');
      user.arsToken = values.get('ars_token');
      commonBenefitsStart(user);
    }

    if (!values.get('ars_token') && vinOdoParameters) {
      datadogRum.addAction("Auto Vin Odo Lookup without ars_token")
      user.lookupType = 'autoVinOdoLookup';
      user.vin = values.get('vin');
      user.mileage = values.get('odo');
      commonBenefitsStart(user);
    }
    if ((!phone || !aniLookupEnabled) && (!values.get('session') || !isEnhanceAuth) && !vinOdoParameters) {
      datadogRum.addAction("User lands on lookup for no good reason?")
      this.props.history.push(generatePath('memberlookup'));
    }
  }

  render() {
    return (
      <div role="main">
        <CarAnn/>
        <div id="lookup" className="c-lookup">
          {this.state.showSpinner && <Spinner/>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonBenefits: state.commonBenefits,
    partnerDetails: state.partnerDetails.partnerDetails,
    serviceRequestPayload: state.serviceRequestPayload,
  };
}

export default connect(mapStateToProps,
  {
    commonBenefitsStart,
    benefitsLookupStart,
    requestPartnerDetails,
    updateContactNumber,
    updateCurrentLocation
  }
)(Lookup);
