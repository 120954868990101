import React from 'react';

export const CustomerSupportIcon = () => 
<svg id="creditcard-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
    <g fill="none" stroke="none" strokeWidth="1">
        <g className="cardAnimation">
            <g className="CreditCard" transform='translate(-32 -612)' fill='#B9C6D3'>
                <g id='Add-Section' className="cardAnimation" transform='translate(0 490)'>
                <g id='Add-Payment' transform='translate(16 112)'>
                <path d='M40,21.9995988 C40,26.0893317 37.9402895,29.7024506 34.8085988,31.8696801 L34.8085988,29.0902344 C34.8085988,26.7962288 33.9508542,24.1371402 30.275952,23.3764836 C31.6263582,22.5925579 32.5394671,21.1338304 32.5394671,19.4632744 C32.5394671,16.9678714 30.5094447,14.937849 28.0132393,14.937849 C25.5186386,14.937849 23.4878138,16.9678714 23.4878138,19.4632744 C23.4878138,21.1338304 24.4009227,22.5925579 25.7513289,23.3764836 C22.0764267,24.1371402 21.2186821,26.7962288 21.2186821,29.0902344 L21.2186821,31.8897396 C18.0701414,29.7249173 16,26.1013674 16,21.9995988 C16,15.3831701 21.3831701,10 27.9995988,10 C34.6168299,10 40,15.3831701 40,21.9995988 Z M31.7073986,19.4632744 C31.7073986,21.4997158 30.0504831,23.1566313 28.0132393,23.1566313 C25.9767978,23.1566313 24.3198823,21.4997158 24.3198823,19.4632744 C24.3198823,19.4063054 24.3263014,19.3517435 24.3287085,19.2955769 C25.0556651,19.257865 26.4654475,19.0981913 27.8631941,18.4602989 C28.2419177,18.2869847 28.5684865,18.1168801 28.8589482,17.9515897 C29.9694427,18.4298084 31.0679014,18.5357226 31.5902511,18.5573869 C31.6640701,18.8478486 31.7073986,19.150346 31.7073986,19.4632744 Z M33.9765304,29.0902344 L33.9765304,32.3904249 C32.214503,33.4078433 30.1764568,34 27.9995988,34 C25.8339741,34 23.8063589,33.4150647 22.0499482,32.4072749 L22.0499482,29.0902344 C22.0499482,25.657651 24.0013373,23.9886998 28.0132393,23.9886998 C32.0259436,23.9886998 33.9765304,25.657651 33.9765304,29.0902344 Z'
                                id='06010profileagentC' />
                </g>
                </g>
                </g>
                <g className="TickTwo" fill="#95D600">
                <path id="06007okayC" d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
            </g>
        </g>
    </g>
</svg>