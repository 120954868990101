import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";

export const french_translation = {
  [TRANSLATION_CONSTANTS.CLOCK_UPDATED]:
    "Cette page a été mise à jour pour la dernière fois {{h:mm:ss A}}",
  [TRANSLATION_CONSTANTS.WELL_TAKE_IT_FROM_HERE]:
    "D'accord, nous prendrons la suite.",
  [TRANSLATION_CONSTANTS.TRACK_AND_MANAGE]:
    "Suivez et gérez votre sauvetage à partir de cette page",
  [TRANSLATION_CONSTANTS.TRACKING_YOUR_RESCUE]: "Suivi de votre sauvetage",
  [TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER]:
    "Nous trouvons un sauveteur à proximité",
  [TRANSLATION_CONSTANTS.WELL_LET_YOU_KNOW]:
    "Nous vous informerons dès que nous désignerons quelqu'un pour vous aider.",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE]: "annuler le sauvetage",
  [TRANSLATION_CONSTANTS.REQUEST_SUMMARY]: "Détails de la demande",
  [TRANSLATION_CONSTANTS.REFERENCE_NUMBER]: "Numéro de réference",
  [TRANSLATION_CONSTANTS.PICKUP_LOCATION]: "Votre lieu de prise en charge",
  [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION]: "Emplacement du panne",
  [TRANSLATION_CONSTANTS.DESTINATION_LOCATION]: "Votre lieu de dépôt",
  [TRANSLATION_CONSTANTS.EXCHANGE_VEHICLE]: "Véhicule D'échange",
  [TRANSLATION_CONSTANTS.NEW_VEHICLE_PICKUP_LOCATION]:
    "Nouveau Véhicule Lieu de Prise En Charge",
  [TRANSLATION_CONSTANTS.NEW_VEHICLE_DROP_0FF_LOCATION]:
    "Nouveau Véhicule Lieu de Dépôt",
  [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_PICKUP_LOCATION]:
    "Désactivé Véhicule Lieu de Prise En Charge",
  [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_DROP_0FF_LOCATION]:
    "Désactivé Véhicule Lieu de Dépôt",
  [TRANSLATION_CONSTANTS.PROVIDER]: "Fournisseur affecté",
  [TRANSLATION_CONSTANTS.CALL]: "Appel",
  [TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER]:
    "Nous avons trouve un sauveteur",
  [TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND]:
    "Un sauveteur fut retrouvé. Nous vous informerons lorsqu'il sera en route.",
  [TRANSLATION_CONSTANTS.RESCUER_ON_WAY]: "Votre fournisseur est en route",
  [TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE]:
    "Votre sauveteur est en route. Continuez à rester avec votre véhicule jusqu'à l'arrivée des secours.",
  [TRANSLATION_CONSTANTS.RESCUER_ARRIVED]: "Votre fournisseur est arrivé",
  [TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE]:
    "L'aide est arrivée. Confirmez que votre sauveteur correspond aux informations ci-dessous.",
  [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED]: "Mission Accomplie!",
  [TRANSLATION_CONSTANTS.RESCUE_COMPLETED]: "Votre service est terminé",
  [TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF]: "Votre véhicule est déposé",
  [TRANSLATION_CONSTANTS.BACK_ON_THE_ROAD]:
    "Il s'agit maintenant de vous remettre en route.",
  [TRANSLATION_CONSTANTS.ASSIGNED]: "Commande acceptée",
  [TRANSLATION_CONSTANTS.EN_ROUTE]: "Driver en route",
  [TRANSLATION_CONSTANTS.ON_SITE]: "Driver sur place",
  [TRANSLATION_CONSTANTS.COMPLETE]: "Demande complétée",
  [TRANSLATION_CONSTANTS.DROPPED_OFF]: "A l'emplacement de sortie",
  [TRANSLATION_CONSTANTS.SERVICE_INCOMPLETE]: "Service incomplet",
  [TRANSLATION_CONSTANTS.GONE_ON_ARRIVAL]:
    "Le fournisseur n'a pas pu localiser le véhicule ou était sur place, mais n'a pas pu joindre le conducteur. Si vous avez toujours besoin de service, s'il vous plaît contactez-nous au",
  [TRANSLATION_CONSTANTS.RESCUE_STEP]: "Étape de sauvetage",
  [TRANSLATION_CONSTANTS.ROADSIDE_ASSISTANCE]: "Assistance routière",
  [TRANSLATION_CONSTANTS.PROVIDED_BY]: "Fourni par",
  [TRANSLATION_CONSTANTS.PRIVACY_POLICY]:
    "Consulter la politique de confidentialité",
  [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION]:
    "Conditions d'utilisation des SMS",
  [TRANSLATION_CONSTANTS.NOTICE_AT_COLLECTION]: "Avis à la collecte",
  [TRANSLATION_CONSTANTS.WEBSITE_ACCESSIBILITY]: "Accessibilité du site Web",
  [TRANSLATION_CONSTANTS.CALCULATED_NO_GPS]:
    "Parfois, il y a des retards inattendus, mais nous ciblons l'heure d'arrivÃ©e estimÃ©e ci-dessus. Continuez à rester avec votre véhicule jusqu'à l'arrivée des secours.",
  [TRANSLATION_CONSTANTS.CALCULATED_GPS]:
    "En fonction de l'emplacement actuel et de la circulation dans la region, nous ciblons l'ETA ci-dessus. Continuez à rester avec votre véhicule jusqu'à l'arrivée des secours.",
  [TRANSLATION_CONSTANTS.ETA_UPDATED]:
    "Petite mise a jour, votre sauveteur est desormais pret a arriver en quelques {projectedETAMinute}. Nous continuerons de travailler avec eux pour nous assurer qu'ils restent sur la bonne voie.",
  [TRANSLATION_CONSTANTS.MOBILE_APP_LABEL]:
    "Téléchargez le {appName} application pour nous contacter plus tard !",
  [TRANSLATION_CONSTANTS.SERVICE_CANCELLED]: "Service Annulé",
  [TRANSLATION_CONSTANTS.HELP_TEXT]:
    "Pour reçois de l'aide, ouvrir l'application {appName} et appelez Driver Care",
  [TRANSLATION_CONSTANTS.CANCEL_ERROR]:
    "Désolé, nous ne pouvons pas annuler votre service",
  [TRANSLATION_CONSTANTS.RESCUE_STATUS_ERROR]:
    "Désolé, nous ne sommes pas capables de recevoir vos informations de sauvetage pour le moment. Nous vérifierons à nouveau dans {timeLeft} secondes",
  [TRANSLATION_CONSTANTS.FAMILY_AND_FRIENDS]:
    "Laissez votre famille et vos amis suivre votre sauvetage et voir les mises à jour en direct.",
  [TRANSLATION_CONSTANTS.SHARE]: "Partager",
  [TRANSLATION_CONSTANTS.TEXT]: "Texte",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_CONFIRMATION_HEADER]:
    "Es-tu sûre de vouloir annuler?",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_BODY]:
    "Si oui, quelle est la raison de l'annulation?",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1]:
    "Le service n'est plus nécessaire",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2]:
    "ETA inacceptable",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3]:
    "Autre",
  [TRANSLATION_CONSTANTS.CANCEL_RESCUE_BUTTON_TEXT]: "Oui, annuler",
  [TRANSLATION_CONSTANTS.VIN_FIND_INSTRUCTIONS]:
    "Le NIV se trouve souvent sur la porte côté conducteur ou le cadre de porte. Aussi, verifier votre carte d’assurance ou d'immatriculation du véhicule.",
  [TRANSLATION_CONSTANTS.HOW_HELP_TEXT]: "Comment pouvons-nous vous aider?",
  [TRANSLATION_CONSTANTS.TOW]: "Remorquage",
  [TRANSLATION_CONSTANTS.LOCKOUT]: "Dévérouillage",
  [TRANSLATION_CONSTANTS.FUEL_DELIVERY]: "Livraison d'essence",
  [TRANSLATION_CONSTANTS.TIRE_CHANGE]: "Changement de pneu",
  [TRANSLATION_CONSTANTS.JUMP_START]: "Survoltage",
  [TRANSLATION_CONSTANTS.MORE_OPTIONS]: "Plus d'options",
  [TRANSLATION_CONSTANTS.CONFIRM_DROP_OFF_LOCATION]:
    "Confirmer la destination de dechargement",
  [TRANSLATION_CONSTANTS.LOCATION_IS]: "Parce que cette emplacement est",
  [TRANSLATION_CONSTANTS.INSTRUCTIONS_CUSTOMER_SERVICE]:
    "vous devrez parler à un représentant du service à la clientèle pour demander votre service.",
  [TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE]:
    "Appeler le service à la clientèle",
  [TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE]: "Montant dû estimé à",
  [TRANSLATION_CONSTANTS.MILES_INCLUDED]: "Miles Included", //need to translate
  [TRANSLATION_CONSTANTS.KILOMETERS_INCLUDED]: "Kilometers Included", //need to translate
  [TRANSLATION_CONSTANTS.ADDITIONAL_MILES]: "Additional Miles", // need to translate
  [TRANSLATION_CONSTANTS.ADDITIONAL_KILOMETERS]: "Additional Kilometers", // need to translate
  [TRANSLATION_CONSTANTS.KILOMETERS]: "kilometers", // need to translate
  [TRANSLATION_CONSTANTS.KILOMETER]: "kilometer", // need to translate
  [TRANSLATION_CONSTANTS.OVER2]: "over", // need to translate
  [TRANSLATION_CONSTANTS.CONFIRM_CONTACT]: "Confirmer le contact",
  [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE]: "Confirm le véhicule",
  [TRANSLATION_CONSTANTS.COLOR]: "Couleur",
  [TRANSLATION_CONSTANTS.FUEL_TYPE]: "Type d’essence",
  [TRANSLATION_CONSTANTS.UNLEADED]: "Sans Plomb",
  [TRANSLATION_CONSTANTS.DIESEL]: "Diesel",
  [TRANSLATION_CONSTANTS.DELIVER_UP_TO]: "Nous livrerons jusqu'à",
  [TRANSLATION_CONSTANTS.COST_FUEL_NOT_INCLUDED]:
    "Le prix de l'essence n'est pas inclus.",
  [TRANSLATION_CONSTANTS.ADD_CONTACT]: "Ajouter un contact",
  [TRANSLATION_CONSTANTS.NAME]: "Nom",
  [TRANSLATION_CONSTANTS.EMAIL]: "Courriel",
  [TRANSLATION_CONSTANTS.PHONE_NUMBER]: "Numéro’essence",
  [TRANSLATION_CONSTANTS.PROVIDE_PHONE_NUMBER]:
    "Veuillez fournir votre numéro de téléphone pour permettre au centre d'assistance routière de vous contacter ou de confirmer le service.",
  [TRANSLATION_CONSTANTS.SEND_TEXT_MESSAGES]:
    "Envoyer les messages texto avec le statut de mon service.",
  [TRANSLATION_CONSTANTS.CONFIRM]: "Confirmer",
  [TRANSLATION_CONSTANTS.SUBMIT_REQUEST]: "Envoyer la demande",
  [TRANSLATION_CONSTANTS.SCHEDULE_A_RIDE]: "Planifiez un transport",
  [TRANSLATION_CONSTANTS.HAVE_LYFT_READY]:
    "Ayez un Lyft prêt pour vous une fois que vous aurez été aidé.",
  [TRANSLATION_CONSTANTS.SCHEDULE]: "Horaire",
  [TRANSLATION_CONSTANTS.HOW_EMBARRASSING]: "Quel embarras",
  [TRANSLATION_CONSTANTS.TECHNICAL_DIFFICULTIES]:
    "Nous avons des difficultés techniques. Nous le réparons en ce moment.",
  [TRANSLATION_CONSTANTS.CALL_HELP_NOW]: "Appelez de l'aide maintenant",
  [TRANSLATION_CONSTANTS.CALL_FOR_ASSISTANCE]: "Appel à l'assistance",
  [TRANSLATION_CONSTANTS.SORRY_NOT_FIND_BENEFITS]:
    "Désolé, nous ne trouvons pas les informations sur vos avantages.",
  [TRANSLATION_CONSTANTS.CAPTURE_ACTUAL_MILEAGE]:
    "Capturez le kilométrage réel (pas le kilométrage du trajet) sur votre tableau de bord ou centre d'information.",
  [TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION]: "Entrez l'emplacement actuel",
  [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_LOCATION]:
    "Confirmer l'emplacement du véhicule",
  [TRANSLATION_CONSTANTS.GEOLOCATION_DISABLED]:
    "Il semble que les services de localisation soient désactivés, veuillez entrer une adresse",
  [TRANSLATION_CONSTANTS.HOME]: "Domicile",
  [TRANSLATION_CONSTANTS.CALL_FOR_HELP]: "appellez pour de l'aide",
  [TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO]:
    "Veuillez fournir les informations sur votre véhicule",
  [TRANSLATION_CONSTANTS.STATE]: "État",
  [TRANSLATION_CONSTANTS.ENTER_STATE]: "Entrer l'état",
  [TRANSLATION_CONSTANTS.SELECT_A_STATE]: "Veuillez sélectionner un État",
  [TRANSLATION_CONSTANTS.LICENSE_PLATE_NUMBER]:
    "Numéro de plaque d'immatriculation",
  [TRANSLATION_CONSTANTS.INVALID_LICENSE_PLATE_NUMBER]:
    "Numéro de plaque d'immatriculation invalide",
  [TRANSLATION_CONSTANTS.CONTINUE]: "Continuer",
  [TRANSLATION_CONSTANTS.OR]: "OU",
  [TRANSLATION_CONSTANTS.SEARCH_BY_VIN]: "Recherche par NIV",
  [TRANSLATION_CONSTANTS.VIN]: "NIV",
  [TRANSLATION_CONSTANTS.ENTER_VALID_VIN]: "Veuillez entrer un NIV valide",
  [TRANSLATION_CONSTANTS.ODOMETER]: "Odomètre",
  [TRANSLATION_CONSTANTS.ENTER_VALID_MILEAGE]:
    "Veuillez entrer un kilométrage valide",
  [TRANSLATION_CONSTANTS.SEARCH_BY_LICENSE_PLATE]:
    "Rechercher par plaque d'immatriculation",
  [TRANSLATION_CONSTANTS.ALL_RIGHT_RESERVED]:
    "Tous les droits sont réservés. Thermes et conditions d'application.",
  [TRANSLATION_CONSTANTS.IS_INFORMATION_CORRECT]:
    "Ces informations sont-elles correctes?",
  [TRANSLATION_CONSTANTS.YES]: "Oui",
  [TRANSLATION_CONSTANTS.NO]: "Non",
  [TRANSLATION_CONSTANTS.IS_WHERE_YOUR_VEHICLE_IS]:
    "C'est là que se trouve votre véhicule?",
  [TRANSLATION_CONSTANTS.ADD_LOCATION_DETAILS]:
    "Ajouter des détails sur l'emplacement",
  [TRANSLATION_CONSTANTS.EXAMPLE_STREET]:
    "Exemple : nom de la rue, magasins à proximité, points de repère, etc.",
  [TRANSLATION_CONSTANTS.SAVE]: "Sauvegarder",
  [TRANSLATION_CONSTANTS.CHARACTERS_REMAINING]: "Caractères restants",
  [TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND]:
    "Il semble que vous n'êtes pas encore enregistré pour les bénéfices roadside. Vous pouvez soit payer seulement pour le service d’aujourd'hui ou nous appeller et demander de devenir membre.",
  [TRANSLATION_CONSTANTS.NO_BENEFITS_AVAILABLE]: "Aucun bénéfice disponible",
  [TRANSLATION_CONSTANTS.DID_NOT_PURCHASE_COVERAGE]:
    "Vous ñ'avez pas souscrit de couverture",
  [TRANSLATION_CONSTANTS.COVERAGE_UP_TO]: "Couverture jusqu'à",
  [TRANSLATION_CONSTANTS.TELL_WHICH_BEST_DESCRIBES_SITUATION]:
    "Veuillez nous dire lequel décrit mieux votre situation.",
  [TRANSLATION_CONSTANTS.STUCK_IN_DITCH]: "Je suis pris dans un fossé",
  [TRANSLATION_CONSTANTS.CAR_NEEDS_RECHARGING]:
    "Ma voiture électronique a besoin d’être rechargée",
  [TRANSLATION_CONSTANTS.SELECT_TOW_LOCATION]:
    "Sélectionnez l'emplacement de remorquage",
  [TRANSLATION_CONSTANTS.SELECT_OR_ENTER_TOW_DESTINATION]:
    "Sélectionnez ou entrez la destination de remorquage",
  [TRANSLATION_CONSTANTS.NO_REPAIR_CENTERS_FOUND]:
    "Can't find a repair center.",
  [TRANSLATION_CONSTANTS.SELECT_ONE]: "Sélectionnez-en un",
  [TRANSLATION_CONSTANTS.BLACK]: "Noir",
  [TRANSLATION_CONSTANTS.BLUE]: "Bleu",
  [TRANSLATION_CONSTANTS.BROWN]: "Marron",
  [TRANSLATION_CONSTANTS.BURGUNDY]: "Bourgogne",
  [TRANSLATION_CONSTANTS.GOLD]: "Or",
  [TRANSLATION_CONSTANTS.GREEN]: "Vert",
  [TRANSLATION_CONSTANTS.GREY]: "Gris",
  [TRANSLATION_CONSTANTS.ORANGE]: "Orange",
  [TRANSLATION_CONSTANTS.PURPLE]: "Violet",
  [TRANSLATION_CONSTANTS.RED]: "Rouge",
  [TRANSLATION_CONSTANTS.SILVER]: "Argent",
  [TRANSLATION_CONSTANTS.WHITE]: "Blanc",
  [TRANSLATION_CONSTANTS.YELLOW]: "Jaune",
  [TRANSLATION_CONSTANTS.WHICE_DRIVER_NEEDS_HELP]:
    "Veuillez sélectionner le conducteur qui a besoin d'aide aujourd'hui.",
  [TRANSLATION_CONSTANTS.VEHICLE]: "Véhicule",
  [TRANSLATION_CONSTANTS.PAYMENT]: "Paiement",
  [TRANSLATION_CONSTANTS.COVERED_BY_BENEFITS]: "Couvert par les prestations",
  [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED]:
    "Le coût total du service sera déterminé une fois qu'un fournisseur de services sera désigné. Le montant pourrait dépasser votre plafond de prestations et nécessiter un paiement direct au fournisseur de services.",
  [TRANSLATION_CONSTANTS.TOTAL]: "Total",
  [TRANSLATION_CONSTANTS.WHERE_ARE_YOUR_KEYS]: "Où sont vos clés?",
  [TRANSLATION_CONSTANTS.IN_MY_VEHICLE]: "Dans mon véhicule",
  [TRANSLATION_CONSTANTS.IN_MY_TRUNK]: "Dans mon coffre de voiture",
  [TRANSLATION_CONSTANTS.LOST_BROKEN]: "Perdu / Volé",
  [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_INFORMATION]:
    "Veuillez confirmer les informations de votre véhicule.",
  [TRANSLATION_CONSTANTS.WE_ARE_SORRY]: "Nous sommes désolés",
  [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTINUE_PPU]:
    "Le véhicule que vous avez sélectionné ne bénéficie actuellement d’aucune couverture routière. Veuillez continuer en tant qu'invité payant à l'utilisation.",
  [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE]:
    "La couverture de ce véhicule est suspendue. Contactez notre représentant pour demander un service.",
  [TRANSLATION_CONSTANTS.WHICH_TIRE_IS_FLAT]: "Quel pneu est à plat ?",
  [TRANSLATION_CONSTANTS.HAVE_A_SPARE_TIRE]: "Avez-vous un pneu de secours?",
  [TRANSLATION_CONSTANTS.LEAVE_PAGE_INFO]:
    "Si vous quittez cette page, les informations que vous avez saisies seront supprimées et votre demande de service ne sera pas traitée. Voulez-vous toujours quitter cette page?",
  [TRANSLATION_CONSTANTS.VEHICLE_STOP_DRIVING]:
    "Votre véhicule s'est arrêté en roulant?",
  [TRANSLATION_CONSTANTS.BATTER_MOST_LIKELY_PROBLEM]:
    "Le démarrage électrique peut ne pas fonctionner.",
  [TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS]:
    "Pas de soucis, ça arrive tout le temps",
  [TRANSLATION_CONSTANTS.SERVICE_CENTERS_EQUIPPED]:
    "Les centres de service sont équipés pour déverrouiller les coffres.",
  [TRANSLATION_CONSTANTS.WITHOUT_A_SPARE_TOW_VEHICLE]:
    "Nous devons remorquer votre véhicule",
  [TRANSLATION_CONSTANTS.DEFAULT]: "Défaut",
  [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN]:
    "Si vous avez perdu ou cassé votre clé, nous pouvons remorquer votre véhicule jusqu'à votre domicile ou jusqu'à un atelier de réparation.",
  [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]:
    "Puisque vos clés sont perdues ou cassées, préférez-vous que votre véhicule soit remorqué jusqu'à un atelier de réparation situé à proximité?",
  [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]:
    "Si vous avez vérifié l'ouverture du coffre/la trappe et que vous ne pouvez toujours pas atteindre vos clés, nous devrons remorquer votre véhicule.",
  [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]:
    "Vous avez probablement besoin d'un ou plusieurs nouveaux pneus. Nous pouvons remorquer votre véhicule jusqu'à un atelier de réparation situé à proximité.",
  [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE]:
    "Continuez si vous souhaitez que nous remorquions votre véhicule jusqu'à votre domicile ou chez un garagiste pour le faire évaluer.",
  [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]:
    "Continuez si vous souhaitez que nous remorquions votre véhicule jusqu'à un atelier de réparation pour le faire évaluer.",
  [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "Continuer vers le remorquage",
  [TRANSLATION_CONSTANTS.STARTS_AT]: "Commence à",
  [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]:
    "déverrouiller mon véhicule",
  [TRANSLATION_CONSTANTS.SORRY_YOU_HAVE_REACHED]:
    "Désolé, vous avez atteint votre",
  [TRANSLATION_CONSTANTS.LIMIT]: "limite",
  [TRANSLATION_CONSTANTS.CAN_STILL_HELP_THANK]:
    "Nous pouvons toujours vous aider, contactez notre représentant pour demander un service. Merci.",
  [TRANSLATION_CONSTANTS.CAN_STILL_HELP]:
    "Nous pouvons toujours vous aider, contactez notre représentant pour demander un service.",
  [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS]:
    "Vous avez épuisé tous vos avantages. Nous pouvons encore vous aider. Contactez notre représentant pour demander un service.",
  [TRANSLATION_CONSTANTS.VEHICLE_WEIGHT_LIMIT]: "Limite de poids du véhicule",
  [TRANSLATION_CONSTANTS.WEIGHT_CLASS_SPECIAL_SERVICE]:
    "Cette catégorie de poids nécessite un service particulier. Veuillez contacter un représentant.",
  [TRANSLATION_CONSTANTS.RUN_INTO_ISSUES_BENEFITS]:
    "Nous avons rencontré un problème avec vos avantages",
  [TRANSLATION_CONSTANTS.ENTER_FIRST_LAST_NAME]:
    "S'il-vous-plaît, entrer votre prénom et votre nom.",
  [TRANSLATION_CONSTANTS.SELECT_A_DRIVER]: "Veuillez sélectionner un pilote.",
  [TRANSLATION_CONSTANTS.ENTER_VALID_EMAIL_ADDRESS]:
    "S'il vous plaît, mettez une adresse email valide.",
  [TRANSLATION_CONSTANTS.ENTER_PHONE_NUMBER]:
    "Veuillez entrer votre numéro de téléphone.",
  [TRANSLATION_CONSTANTS.PAYMENT_INFO]: "Information de paiement",
  [TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE]:
    "Veuillez entrer un code postal à 5 chiffres.",
  [TRANSLATION_CONSTANTS.DRIVER]: "Conducteur",
  [TRANSLATION_CONSTANTS.BILLING_ZIP]: "Code postal de facturation",
  [TRANSLATION_CONSTANTS.CONTACT_PAYMENT]: "Contact/Paiement",
  [TRANSLATION_CONSTANTS.TAKE_UP_5_DAYS_CREDIT_REFUND]:
    "Veuillez noter que le traitement de votre remboursement par carte de crédit peut prendre jusqu'à 5 jours.",
  [TRANSLATION_CONSTANTS.CANCEL_REQUEST_SUCCESFULLY]:
    "Votre demande d'annulation a été traitée avec succès.",
  [TRANSLATION_CONSTANTS.APPRECIATE_FEEDBACK]:
    "Nous apprécions vos commentaires. Merci!",
  [TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST]: "Continuer en tant qu'invité",
  [TRANSLATION_CONSTANTS.USE_PPU_OPTION_TO_PAY]:
    "Utilisez notre option de paiement à l'utilisation pour payer l'assistance routière dont vous avez besoin aujourd'hui.",
  [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED_SHARE_FEEDBACK]:
    "Mission accomplie! Veuillez partager vos commentaires sur l'écran suivant.",
  [TRANSLATION_CONSTANTS.QUICK_UPDATE]:
    "Mise à jour rapide, votre sauveteur est maintenant prêt à arriver dans",
  [TRANSLATION_CONSTANTS.WE_WILL_CONTINUE_TO_WORK]:
    "Nous continuerons de travailler avec eux pour nous assurer qu'ils restent sur la bonne voie.",
  [TRANSLATION_CONSTANTS.WE_CAN_HELP]: "Nous pouvons aider",
  [TRANSLATION_CONSTANTS.GIVE_US_A_CALL]:
    "S'il vous plaît appelez-nous afin que nous puissions vous mettre sur votre chemin.",
  [TRANSLATION_CONSTANTS.LOOKS_LIKE_CAN_NOT_CHARGE]:
    "Il semble que nous ne puissions pas recharger votre véhicule, mais nous pouvons vous aider",
  [TRANSLATION_CONSTANTS.CONTINUE_LIKE_US_TO_TOW]:
    "Continuez si vous souhaitez que nous remorquions votre véhicule jusqu'à votre domicile ou une borne de recharge à proximité.",
  [TRANSLATION_CONSTANTS.INCLUDED]: "Inclus",
  [TRANSLATION_CONSTANTS.ADDITIONAL]: "Supplémentaire",
  [TRANSLATION_CONSTANTS.OVER]: "plus de",
  [TRANSLATION_CONSTANTS.WEBSITE]: "Site Internet",
  [TRANSLATION_CONSTANTS.REVIEW_SUMMARY]: "Résumé des commentaires",
  [TRANSLATION_CONSTANTS.REVIEWS]: "Commentaires",
  [TRANSLATION_CONSTANTS.REPAIR_SHOP_NOT_BEEN_REVIEWED]:
    "Cet atelier de réparation n'a pas été évalué.",
  [TRANSLATION_CONSTANTS.SHARE_YOUR_FEEBACK]:
    "Veuillez partager vos commentaires ci-dessous.",
  [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_RESCUER]:
    "Comment s'est passée votre expérience avec le sauveteur?",
  [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_SITE]:
    "Comment s'est passée votre expérience avec notre site?",
  [TRANSLATION_CONSTANTS.SHARE_ANY_ADDITIONAL_COMMENTS]:
    "Veuillez partager tout autre commentaire ci-dessous",
  [TRANSLATION_CONSTANTS.SUBMIT]: "Soumettre",
  [TRANSLATION_CONSTANTS.SKIP]: "Sauter",
  [TRANSLATION_CONSTANTS.BENEFITS]: "Avantages",
  [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED_PROVIDER]:
    "Le coût final sera déterminé une fois votre fournisseur désigné. Tout montant excédant vos prestations doit être payé directement au fournisseur de services.",
  [TRANSLATION_CONSTANTS.SERVICE_NOT_AVAILABLE]:
    "Le coût final sera déterminé une fois votre fournisseur désigné. Tout montant excédant vos prestations doit être payé directement au fournisseur de services.",
  [TRANSLATION_CONSTANTS.WE_SORRY_SERVICE_NOT_AVAILABLE]:
    "Nous sommes désolés, le service n'est pas encore disponible dans cette zone via cette application.",
  [TRANSLATION_CONSTANTS.ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION]:
    "Veuillez entrer des détails supplémentaires sur votre position actuelle ou votre lieu de dépôt",
  [TRANSLATION_CONSTANTS.I_E_STREET_NAME]:
    "c'est-à-dire le nom de la rue, le numéro et le code postal",
  [TRANSLATION_CONSTANTS.OK]: "D'accord",
  [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION_LINK]:
    "https://roadsidemobile.com/tac/sms#french",
  [TRANSLATION_CONSTANTS.SECURITY_FACILITY]:
    "mais comme il s'agit d'une installation sécurisée, vous pouvez poursuivre votre demande de remorquage",
  [TRANSLATION_CONSTANTS.THIS_LOCATION_IS]: "Cet emplacement est",
  [TRANSLATION_CONSTANTS.CLOSED]: "fermé",
  [TRANSLATION_CONSTANTS.CLOSING_SOON]: "bientôt fermé",
  [TRANSLATION_CONSTANTS.SUSPENDED_VEHICLE_TO_CALL_CENTER]:
    "Vous avez sélectionné un véhicule qui n'est pas couvert pour un {serviceType}. Nous pouvons encore vous aider. Contactez notre représentant pour demander un service.",
  [TRANSLATION_CONSTANTS.POLICY_NUMBER]: "Numéro de police",
  [TRANSLATION_CONSTANTS.MEMBER_NUMBER]: "Numéro d'adhérent",
  [TRANSLATION_CONSTANTS.FIND_POLICY_NUMBER]:
    "Vous trouverez votre numéro de police au recto de votre carte.",
  [TRANSLATION_CONSTANTS.FIND_MEMBER_NUMBER]:
    "Vous trouverez votre numéro de membre au recto de votre carte.",
  [TRANSLATION_CONSTANTS.LOST_POLICY_NUMBER]:
    "Si vous avez perdu votre numéro de police et/ou votre carte, veuillez contacter notre service clientèle au",
  [TRANSLATION_CONSTANTS.LOST_MEMBER_NUMBER]:
    "Si vous avez perdu votre numéro d'adhérent et/ou votre carte, veuillez contacter notre service clientèle à l'adresse suivante",
  [TRANSLATION_CONSTANTS.MONDAY_FRIDAY]: "Lundi - Vendredi",
  [TRANSLATION_CONSTANTS.WHAT_IS_THIS]: "Qu'est-ce que c'est?",
  [TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS]:
    "Nous avons besoin de quelques informations pour trouver vos prestations",
  [TRANSLATION_CONSTANTS.PHONE_LOOKUP_FAILURE]:
    "Désolé, nous n'avons pas pu localiser vos prestations avec votre numéro de téléphone.",
  [TRANSLATION_CONSTANTS.FN_LN_ZIP_LOOKUP_FAILURE]:
    "Désolé, nous n'avons pas pu localiser vos prestations avec votre prénom, votre nom de famille et votre code postal.",
  [TRANSLATION_CONSTANTS.NAME_STATE_ZIP_LOOKUP_FAILURE]:
    "Désolé, nous n'avons pas pu localiser vos prestations avec votre nom, votre état et votre code postal.",
  [TRANSLATION_CONSTANTS.FIRST_NAME]: "Nom",
  [TRANSLATION_CONSTANTS.LAST_NAME]: "Nom de famille",
  [TRANSLATION_CONSTANTS.ZIP]: "Zip",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME]:
    "Veuillez saisir votre prénom.",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME]:
    "Veuillez saisir votre nom de famille.",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_ACCOUNT]:
    "Veuillez saisir les informations telles qu'elles apparaissent sur votre compte (par exemple, Michael Smith vs. Mike Smith)",
  [TRANSLATION_CONSTANTS.ZIP_CODE]: "Code postal",
  [TRANSLATION_CONSTANTS.AS_APPEARS_ACCOUNT]:
    "(Tel qu'il apparaît sur votre compte)",
  [TRANSLATION_CONSTANTS.UP_TO]: "Jusqu'à",
  [TRANSLATION_CONSTANTS.MILES]: "Miles",
  [TRANSLATION_CONSTANTS.FUEL_PRICE]: "Prix du carburant",
  [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS_TO_PAY]:
    "Vous avez épuisé toutes vos prestations. Pour payer le service d'aujourd'hui, choisissez l'une des options ci-dessous.",
  [TRANSLATION_CONSTANTS.VEHICLE_INFO]: "Informations sur le véhicule",
  [TRANSLATION_CONSTANTS.ADD_VEHICLE]: "Ajouter un véhicule",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_CAR_MODEL]:
    "Veuillez saisir une date de modèle de voiture postérieure à 1970 et antérieure à",
  [TRANSLATION_CONSTANTS.YEAR]: "année",
  [TRANSLATION_CONSTANTS.MAKE_MODEL]: "Marca/Modelo",
  [TRANSLATION_CONSTANTS.YYYY]: "AAAA",
  [TRANSLATION_CONSTANTS.ADD]: "Ajouter",
  [TRANSLATION_CONSTANTS.ERROR_MEMBERSHIP_NUMBER]:
    "Veuillez saisir votre numéro de membre à 9 ou 10 chiffres.",
  [TRANSLATION_CONSTANTS.ERROR_POLICY_NUMBER]:
    "Veuillez saisir un numéro de police commençant par 4 caractères et se terminant par 9 chiffres. Exemple : XXXX001234567",
  [TRANSLATION_CONSTANTS.PLEASE_CONFIRM_VEHICLE]:
    "Veuillez confirmer votre véhicule OU lancer une nouvelle recherche par VIN",
  [TRANSLATION_CONSTANTS.FOUND]: "trouvé",
  [TRANSLATION_CONSTANTS.VEHICLES]: "Véhicule(s)",
  [TRANSLATION_CONSTANTS.PLEASE_SELECT_OPTION]:
    "Veuillez sélectionner une option",
  [TRANSLATION_CONSTANTS.ESTIMATED_MILEAGE]: "Estimation du kilométrage",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_VALID_ESTIMATED_MILEAGE]:
    "Veuillez saisir un kilométrage valide",
  [TRANSLATION_CONSTANTS.MEMBERSHIP_2_DAY_WAIT_PERIOD]:
    "Votre adhésion n'est pas encore éligible à la couverture routière en raison de la période d'attente de 2 jours. Pour payer le service d'aujourd'hui, choisissez l'une des options ci-dessous",
  [TRANSLATION_CONSTANTS.CLAIMS_USED_UP]: "Créances épuisées",
  [TRANSLATION_CONSTANTS.ETA_CALCULATING]: "ETA: calcul",
  [TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC]:
    "Nous recherchons un fournisseur proche de chez vous. Cela peut prendre jusqu'à 15 minutes. Restez dans un endroit secours jusqu'à l'aide arrive",
  [TRANSLATION_CONSTANTS.START_OVER_ALERT_MESSAGE]:
    "Pour une meilleure expérience, veuillez recommencer en cliquant sur le bouton Demander un service sur la page d'assistance routière.",
  [TRANSLATION_CONSTANTS.PRIMER_HELP]: "aidez-nous à vous trouver rapidement",
  [TRANSLATION_CONSTANTS.SHARING_PHONE]:
    "Partager la position de votre téléphone est le moyen <strong>le plus rapide</strong> et <strong>le plus précis</strong> pour l'assistance routière de vous trouver.",
  [TRANSLATION_CONSTANTS.CLICK_ALLOW]:
    "Cliquez sur <strong>autoriser</strong> sur l'écran suivant pour partager l'autorisation suivante",
  [TRANSLATION_CONSTANTS.LOCATION]: "Emplacement",
  [TRANSLATION_CONSTANTS.PRIMER_DATA]:
    "Vos données et votre position seront partagées avec votre fournisseur de services et ne seront utilisées que pour ce sauvetage.",
  [TRANSLATION_CONSTANTS.CANCEL]: "Annuler",
  [TRANSLATION_CONSTANTS.ALLSTATE_FOOTER_TEXT]: "Allstate Insurance Company",
  [TRANSLATION_CONSTANTS.CONTACT]: "Contact", //need to translate
  [TRANSLATION_CONSTANTS.SORRY_TO_SEE_YOU_GO]: "Sorry to see you go", //needs translation
  [TRANSLATION_CONSTANTS.CALL_FOR_HELP_DESCRIPTION]:
    "Before we connect you, please select an option below or provide more detail in the box", //needs translation
  [TRANSLATION_CONSTANTS.ISSUE_IN_ROADSIDE_COVERAGE]:
    "There is an issue with my roadside coverage", //needs translation
  [TRANSLATION_CONSTANTS.SCHEDULE_SERVICE_FOR_FUTURE_TIME]:
    "I want to schedule a service for a future time", //needs translation
  [TRANSLATION_CONSTANTS.TALK_TO_PERSON]: "I just want to talk to a person", //needs translation
  [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION_WRONG]:
    "My disablement location is wrong", //needs translation
  [TRANSLATION_CONSTANTS.OTHER_SPECIFY]: "Other, please specify", //needs translation
  [TRANSLATION_CONSTANTS.GO_BACK]: "go back", //needs translation
  [TRANSLATION_CONSTANTS.WHAT_IS_YOUR_FUEL_TYPE]:
    "Quel est votre type de carburant ?",
  [TRANSLATION_CONSTANTS.OUT_OF_POCKET]: "De votre poche {{price}}",
  [TRANSLATION_CONSTANTS.PER_RESCUE]: "/ sauvetage",
  [TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO]: "Covered up to",
  [TRANSLATION_CONSTANTS.SERVICE_COVERED]: "Covered",
  [TRANSLATION_CONSTANTS.SERVICE_BASED_BANNER]:
    'Your benefits offer a maximum coverage per service. Any service labeled "out-of-pocket" is not covered.',
  [TRANSLATION_CONSTANTS.NO_SUGGESTIONS]:
    "Sorry. Can't find a repair center. You may try to search or select again or call the customer service.",
  [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]:
    "Sorry. Can't find a repair center. Please call customer service.",
  [TRANSLATION_CONSTANTS.RETRIEVING_LOCATIONS]: "Getting Location…",
  [TRANSLATION_CONSTANTS.ENTER_MANUAL_LOCATION]:
    "Sorry. Can't find a repair center. You may enter a location manually or call customer service.",
  [TRANSLATION_CONSTANTS.LIVE_CHAT]: "live chat (english only)",
  [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE]:
    "We’re unable to find your benefits information.",
  [TRANSLATION_CONSTANTS.ENTER_COMPANY_NAME]: "Please enter your company name.",
  [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SIGNATURE_CLUB]:
    "Signature Motor Club.",
  [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SLOGAN]:
    "Allstate. You're in good hands.",
  [TRANSLATION_CONSTANTS.STATUS_OPEN24]: "Open 24 Hours",
  [TRANSLATION_CONSTANTS.STATUS_CLOSED]: "Closed",
  [TRANSLATION_CONSTANTS.STATUS_CLOSING_SOON]:
    "Closing Soon • Open until {{time}} today",
  [TRANSLATION_CONSTANTS.STATUS_OPEN]: "Open until {{time}} today",
  [TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT]: "Closed • Opens at {{time}}",
  [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_LOCATION]:
    "Passenger drop-off location",
  [TRANSLATION_CONSTANTS.FINDING_PROVIDER]: "Finding provider",
  [TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED]: "Provider assigned",
  [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT]: "Picking up replacement",
  [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE]: "Provider en route",
  [TRANSLATION_CONSTANTS.PROVIDER_ARRIVED]: "Provider arrived",
  [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF]: "Passenger drop off",
  [TRANSLATION_CONSTANTS.RESCUE_COMPLETE]: "Rescue complete",
  [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT_DESC]:
    "Your service provider is on the way to pick up your replacement vehicle. They'll be en route to your location soon.",
  [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_DESC]:
    "Your service provider is on the way with your replacement vehicle. Stay with your vehicle until help arrives.",
  [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_DESC]:
    "The passenger has been dropped off at the passenger drop-off location.",
  [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_DESC]:
    "Your rescue is complete and your vehicle was dropped off at the rental company.",
  [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_PASSENGER_DESC]:
    "Your rescue is complete and your vehicle has been dropped off.",
  [TRANSLATION_CONSTANTS.REPLACEMENT_VEHICLE_DELIVERY]:
    "Replacement vehicle delivery",
  [TRANSLATION_CONSTANTS.PROVIDER_TEN_MINS_ALERT]:
    "Your service provider is about 10 minutes away from your location. Stay with your vehicle until help arrives.",
  [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_TITLE]: "Rescue in progress",
  [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_DESC]:
    "View the rescue tracker or chat with a representative to check the status of your rescue. Your current rescue must be completed before requesting a new one",
  [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT]:
    "view rescue tracker",
  [TRANSLATION_CONSTANTS.COVERS_UPTO]: "Covers up to",
  [TRANSLATION_CONSTANTS.SERVICE_FEE_REDUCTION_REASON]:
    "* Coverage amounts reflect a $0.55 service fee.",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_ACCIDENT]: "I was involved in an accident",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_VANDALISM]: "My car has been vandalized",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_TIRE_WITHOUT_SPARE]: "I have a flat",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_MECHANICAL_KEY]: "I’m locked out of my car",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_BATTERY]: "My vehicle won't start/battery",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_OTHER]: "Something else",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_TITLE]:
    "Please tell us which best describes your tow situation.",
  [TRANSLATION_CONSTANTS.TOW_TRIAGE_REASON_LABEL]:
    "What do you need help with?",
  [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE_POLICY]:
    "We couldn't find your benefits with the details you provided. Try again using your policy number.",
  [TRANSLATION_CONSTANTS.NEW_JOB_DATA_DRIVEN_ETA_DESC]:
    "Please note the estimated arrival time and stay in a safe location until help arrives.",
  [TRANSLATION_CONSTANTS.BOOKED_DATA_DRIVEN_ETA_DESC]:
    "Your assigned provider is on schedule to arrive at the estimated time.",
  [TRANSLATION_CONSTANTS.BOOKED_NOT_DATA_DRIVEN_ETA_DESC]:
    "We've updated the arrival time with a more accurate estimate based on the provider's status.",
  [TRANSLATION_CONSTANTS.CARD_RETRY_MESSAGE]:
    "We’re unable to process your payment. Please edit your card information.",
  [TRANSLATION_CONSTANTS.TOW_REASON_NOT_COVERED_MSG]:
    "The tow reason you selected isn’t covered by your roadside benefits. Use our pay-per-use guest option to get roadside help today.",
  [TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION_SCHEDULED_SERVICE]:
    "By clicking <strong>continue</strong> below, you agree to receive text messages about your rescue status.",
  [TRANSLATION_CONSTANTS.RENTAL_AGREEMENT_NUMBER]:
    "Rental Agreement (RA) Number",
  [TRANSLATION_CONSTANTS.SEARCH_BY_RENTAL_AGREEMENT]:
    "search by rental agreement",
  [TRANSLATION_CONSTANTS.SEARCH_BY_MOTOR_VEHICLE_ASSET]:
    "search by motor vehicle asset",
  [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_RA]:
    "Enter only the digits on your rental agreement number",
  [TRANSLATION_CONSTANTS.ERROR_RA_NUMBER]:
    "Please enter only the 8 or 9 digits on your rental agreement number",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_TITLE]:
    "Visually inspect the tire",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_CONTENT]:
    "If the tire pressure light comes on due to a significant temperature change and the tire isn't visibly flat or damaged, you can drive it a short distance to a service station for air.",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_TITLE]:
    "Utilize Tire Inflation Kit",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_CONTENT]:
    "If the sidewall of the flat tire is undamaged, you can use a tire inflation kit. Check your car's trunk; the kit might be in the side panel or under the trunk floor.",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_TITLE]:
    "Confirm a working spare is available",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_CONTENT]:
    "You can find a spare tire in the trunk, side panel, or under the rear of the vehicle. An internet search might help you locate it.",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_TITLE]:
    "Check the size of the spare tire",
  [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_CONTENT]:
    "If your spare tire is not full-sized, consider driving to the nearest {brand} location to exchange your vehicle once the tire change has been performed.",
  [TRANSLATION_CONSTANTS.REIMBURSEMENT_MESSAGE]:
    "Your coverage requires you to prepay for your roadside service and submit a claim for reimbursement.",
};
