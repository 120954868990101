import {
  FETCH_FORM_QUESTIONS,
  FETCH_FORM_QUESTIONS_ERROR,
  FETCH_FORM_QUESTIONS_SUCCESS,
  GET_PREFILLED_DATA_SUCCESS,
  REFRESH_REDUCERS,
  SAVE_FORM_TO_STATE,
} from "../action";

const initialState = {
  questions: [],
  // type -> [questionId]: responseText
  formResponse: {},
  isFormComplete: false,
  prefilledData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_QUESTIONS:
      return {
        ...state,
      };
    case FETCH_FORM_QUESTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_FORM_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      };
    case SAVE_FORM_TO_STATE:
      return {
        ...state,
        formResponse: action.payload,
        isFormComplete: true,
      };
    case GET_PREFILLED_DATA_SUCCESS:
      return {
        ...state,
        prefilledData: action.payload,
      };
    case REFRESH_REDUCERS:
      return { ...initialState };
    default:
      return state;
  }
}
