import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, FormGrid } from "mesh-component-library";
import { YesNoButtonGroup } from "./YesNoButtonGroup";
import { FormFieldV2 } from "../../components/FormFieldV2/FormFieldV2";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useHistory } from "react-router-dom";
import { generatePath } from "../../utilities/RedirectUtils";
import { datadogRum } from "@datadog/browser-rum";
import Spinner from "../../components/spinner";
import { useSelector } from "react-redux";
import { getIncidentDetails } from "../../selector";
import _isEmpty from "lodash/isEmpty";

const TitleText = styled.h1`
  color: #2f3847;
  text-align: left;
`;

const Container = styled.div`
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const IncidentReportForm = ({
  customerType,
  onCompleteForm,
  onInit,
  prefilledData,
  questions,
}) => {
  const history = useHistory();
  const isUberDriver = customerType === "uber";
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [formState, setFormState] = useState({});
  const getTranslatedText = useLocale();
  const incidentDetails = useSelector(getIncidentDetails);
  if (!_isEmpty(incidentDetails)) {
    questions = incidentDetails;
  }

  /** Fetch questions via API */
  useEffect(() => {
    if (_isEmpty(incidentDetails)) {
      onInit && onInit();
    }
  }, []);

  /** Initialize form state */
  useEffect(() => {
    setFormState(initializeFormState());
  }, [questions]);

  /** Validate form is filled out correctly */
  useEffect(() => {
    if (validateFormIsComplete()) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [formState]);

  const handleSubmit = (data) => {
    onCompleteForm(data);
    history.push(generatePath("location"));
    datadogRum.addAction("Incident Report Form Submitted", data);
  };

  const initializeFormState = () => {
    let initialState = {};
    questions.map(({ questionId, responseText, questionText }) => {
      if (isUberQuestion(questionText) && !isUberDriver) {
        initialState = {
          ...initialState,
          [questionId]: "N/A",
        };
      } else {
        initialState = {
          ...initialState,
          [questionId]: responseText || prefilledData[questionId]?.value,
        };
      }
    });

    return initialState;
  };

  const onInputChange = (value, questionId) => {
    const maxLength = 150;
    if(value?.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    setFormState((prevState) => ({ ...prevState, [questionId]: value }));
  };

  const orderArrayOfQuestions = (questionArray) =>
    Array.from(questionArray.sort((a, b) => a.questionOrder - b.questionOrder));

  /**
   * There are some questions that require specific UI elements
   * The only way to key off is the question id by the backend, so these are hardcoded and must be maintained manually
   */
  const renderQuestions = (questions) =>
    orderArrayOfQuestions(questions).map(
      ({ questionId, questionText, responseType }) => {
        if (questionId === 751 || questionId === 4732) {
          /** This question requires a larger text area */
          return renderFormField({
            questionId,
            questionText,
            displayTextArea: true,
          });
        } else if (questionId === 4683) {
          /** This question response is defaulted to "One", and disabled */
          return wrapQuestionInGridColumn(
            <FormFieldV2
              value="One"
              label={questionText}
              required={true}
              key={`incidentReportFormQuestion-${questionId}`}
              disabled
            />
          );
        } else if (questionId === 814) {
          return renderYesNoButtonGroup({
            questionId,
            questionText,
            disabled: true,
          });
        } else if (isUberQuestion(questionText)) {
          /** Only display these questions if the customer is uberDrive, and trim the content */
          return isUberDriver
            ? responseType === "radio_button"
              ? renderYesNoButtonGroup({
                  questionId,
                  questionText,
                  isUber: true,
                })
              : renderFormField({ questionId, questionText, isUber })
            : null;
        }
        return responseType === "radio_button"
          ? renderYesNoButtonGroup({ questionId, questionText })
          : renderFormField({ questionId, questionText });
      }
    );

  const renderYesNoButtonGroup = ({
    questionId,
    questionText,
    isUber = false,
    disabled = false,
  }) =>
    wrapQuestionInGridColumn(
      <YesNoButtonGroup
        onChange={(e) => onInputChange(e.target.value, questionId)}
        questionId={questionId}
        label={isUber ? trimUberQuestion(questionText) : questionText}
        value={formState[questionId]}
        key={`incidentReportFormQuestion-${questionId}`}
        name={`incidentReportFormQuestion-${questionId}`}
        disabled={disabled}
      />
    );

  const renderFormField = ({
    questionId,
    questionText,
    displayTextArea = false,
    isUber = false,
  }) =>
    wrapQuestionInGridColumn(
      <FormFieldV2
        label={isUber ? trimUberQuestion(questionText) : questionText}
        value={formState[questionId]}
        onChange={(e) => onInputChange(e.target.value, questionId)}
        required={true}
        key={`incidentReportFormQuestion-${questionId}`}
        minLength={2}
        maxLength={150}
        type={displayTextArea ? "textarea" : "text"}
        rows={displayTextArea ? 5 : null}
        showCharCount
      />
    );

  const trimUberQuestion = (questionText) =>
    questionText.replace("When Uber Driver:", "");

  const isUberQuestion = (questionText) =>
    questionText.includes("When Uber Driver:");

  const validateFormIsComplete = () => {
    return questions.every(({ questionId, responseType }) => {
      let fieldValue = formState[questionId];
      if (fieldValue) {
        const isYesOrNo = responseType === "radio_button";
        if (isYesOrNo) {
          return fieldValue?.length >= 1;
        } else {
          return fieldValue?.length >= 2;
        }
      }
    });
  };

  const wrapQuestionInGridColumn = (questionComponent) => (
    <FormGrid.Column>{questionComponent}</FormGrid.Column>
  );

  return questions.length > 0 ? (
    <Container>
      <TitleText>
        {getTranslatedText(
          TRANSLATION_CONSTANTS.TELL_US_MORE_ABOUT_THIS_INCIDENT
        )}
      </TitleText>
      <Box style={{ textAlign: "left" }}>
        <FormGrid>{renderQuestions(questions)}</FormGrid>
      </Box>
      <Button
        hasUpgrade
        type="submit"
        onClick={() => handleSubmit(formState)}
        utils={{ fullWidth: true }}
        disabled={disableSubmit}
      >
        Complete
      </Button>
    </Container>
  ) : (
    <Spinner />
  );
};
