import React from 'react';

export const CreditCardIcon = () => 
<svg id="creditcard-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
    <defs />
    <g fill="none" stroke="none" strokeWidth="1">
        <g className="cardAnimation">
            <g className="CreditCard" fill="#B9C6D3">
                <path id="05016creditcardC" d="M18.4792109,14.261424 C18.4792109,15.0870085 17.8077843,15.7577046 16.9829304,15.7577046 L5.04702444,15.7577046 C4.22217056,15.7577046 3.5514745,15.0870085 3.5514745,14.261424 L3.5514745,7.73419235 C3.5514745,6.90860786 4.22217056,6.2379118 5.04702444,6.2379118 L16.9829304,6.2379118 C17.8077843,6.2379118 18.4792109,6.90860786 18.4792109,7.73419235 L18.4792109,14.261424 L18.4792109,14.261424 Z M16.603746,10.9978082 L13.9377657,10.9978082 C13.7127391,10.9978082 13.5278959,11.1826514 13.5278959,11.407678 L13.5278959,11.9329835 C13.5278959,12.1580101 13.7127391,12.3428533 13.9377657,12.3428533 L16.603746,12.3428533 C16.8295032,12.3428533 17.0136158,12.1580101 17.0136158,11.9329835 L17.0136158,11.407678 C17.0136158,11.1826514 16.8295032,10.9978082 16.603746,10.9978082 L16.603746,10.9978082 Z M4.35075717,9.92089532 L17.6791977,9.92089532 L17.6791977,8.02424283 L4.35075717,8.02424283 L4.35075717,9.92089532 Z M11,0 C4.92501328,0 0,4.92501328 0,11 C0,17.0749867 4.92501328,22 11,22 C17.0749867,22 22,17.0749867 22,11 C22,4.92501328 17.0749867,0 11,0 L11,0 Z" />
            </g>
            <g className="TickTwo" fill="#95D600">
                <path id="06007okayC" d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
            </g>
        </g>
    </g>
</svg>