var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Box, Button, Divider, Dropdown, Flex, FormOptionGroup, Grid, Headline, Icon, Sticky, Type, Utility, } from "mesh-component-library";
import { PlusIcon, CloseIcon } from "mesh-icon-library";
import { ContinueAsPpuModal } from "../../components/continue-as-ppu-modal/ContinueAsPpuModal";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import CONSTANTS, { FUEL_SERVICE_ID, GAS, JJ, supportedColors, SUSPENDED_VEHICLE_TO_CALL_CENTER, TOW_SERVICE_ID, UNSUPPORTED_EQUIPMENT_CLASSES, } from "../../app-consts/appConstants";
import { getServiceRequestPayload, getCommonBenefits, getServiceInfo, getDestination, getPartnerDetails, } from "../../selector";
import titleCase from "../../utils/titleCase";
import { clearCCardInfo, updateCCardInfo, updateLoggedInFlag, updatePpuFlag, updateServiceTransition, updateServiceTypeOptions, updateVehicle, updateVehicleInfo, updatePromoCode, updateAccidentQuestions } from "../../action";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import { sendFuelTypeToJacada, sendVehicleToJacada, } from "../../jacada/sendJacadaEventData";
import { datadogRum } from "@datadog/browser-rum";
import { generatePath } from '../../utilities/RedirectUtils';
import { sendJacadaRequest } from "../../api";
import { USER_OUT_OF_CLAIMS } from "../../app-consts/errorMessageConstants";
import { TimelineMax } from "gsap";
import { getTranslatedColors } from "../../utils/translatedTextUtils";
import { FuelAlert } from "../../components";
import { AccidentQuestions } from "../../components/AccidentQuestions";
export var VehicleSelect = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var getTranslatedText = useLocale();
    var history = useHistory();
    var dispatch = useDispatch();
    var serviceRequestPayload = useSelector(getServiceRequestPayload);
    var commonBenefits = useSelector(getCommonBenefits);
    var serviceInfo = useSelector(getServiceInfo);
    var destination = useSelector(getDestination);
    var vehicleValue = useSelector(function (state) { return state.vehicleValue; });
    var partnerDetails = useSelector(getPartnerDetails);
    var isJJ = partnerDetails.partnerCode === JJ;
    var _k = useState(((_a = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.vehicles) === null || _a === void 0 ? void 0 : _a.length) === 1
        ? titleCase((_b = commonBenefits.vehicles[0]) === null || _b === void 0 ? void 0 : _b.fuelType)
        : ""), fuelType = _k[0], setFuelType = _k[1];
    var _l = useState(vehicleValue.selectedVehicleIndex), selectedVehicleIndex = _l[0], setSelectedVehicleIndex = _l[1];
    var _m = useState(false), showSuspendedCoverageModal = _m[0], setShowSuspendedCoverageModal = _m[1];
    var _o = useState(false), showSuspendedCoverageNonPPUModal = _o[0], setShowSuspendedCoverageNonPPUModal = _o[1];
    var _p = useState(false), continueToPpu = _p[0], setContinueToPpu = _p[1];
    var _q = useState([]), vehicleList = _q[0], setVehicleList = _q[1];
    var _r = useState(((_d = (_c = serviceRequestPayload === null || serviceRequestPayload === void 0 ? void 0 : serviceRequestPayload.serviceRequest) === null || _c === void 0 ? void 0 : _c.vehicle) === null || _d === void 0 ? void 0 : _d.color) ||
        ((_f = (_e = serviceRequestPayload === null || serviceRequestPayload === void 0 ? void 0 : serviceRequestPayload.serviceRequest) === null || _e === void 0 ? void 0 : _e.vehicles) === null || _f === void 0 ? void 0 : _f.color) ||
        ""), vehicleColor = _r[0], setVehicleColor = _r[1];
    var _s = useState(undefined), accidentQuestionResponse = _s[0], setAccidentQuestionResponse = _s[1];
    var fuelCostCovered = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.experience.fuelCostCovered;
    var unitOfMeasure = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.experience.unitOfMeasure;
    var addAVehicle = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.experience.addAVehicle;
    useEffect(function () {
        document.title = "Vehicle Select | Roadside Assistance";
        var vehicles = determineVehicleList();
        if (vehicles) {
            setVehicleList(vehicles);
            if (vehicles.length === 0) {
                addAnotherVehicle();
            }
            if (vehicles.length === 1) {
                var vehicleVal = vehicleList[0] || vehicles[0];
                onSelectVehicle(0, vehicleVal);
            }
        }
    }, []);
    var storeAccidentQuestionResponse = function () {
        dispatch(updateAccidentQuestions(accidentQuestionResponse));
    };
    var determineVehicleList = function () {
        var addedVehicleFlag = vehicleValue.addedVehicleFlag;
        var vehicles = commonBenefits.vehicles;
        var serviceRequest = serviceRequestPayload.serviceRequest;
        var experience = partnerDetails.experience;
        if (addedVehicleFlag) {
            var vehicleList_1 = __spreadArray([], vehicles, true);
            vehicleList_1.push(serviceRequest.vehicle || serviceRequest.vehicles);
            if (experience.supportRentalOrBorrowed && vehicleList_1.length > 0) {
                vehicleList_1[vehicleList_1.length - 1].suspendIndicator = "N";
            }
            return vehicleList_1;
        }
        else {
            return vehicles;
        }
    };
    var addAnotherVehicle = function () {
        history.push(generatePath("add-vehicle"));
    };
    var colorIsSupported = function (vehicleColor) {
        return (supportedColors.filter(function (colorObject) {
            return colorObject.text.toLowerCase() === vehicleColor.toLowerCase();
        }).length > 0);
    };
    var callCallCenterBtn = function () {
        var phone = partnerDetails.phone || localStorage.getItem('callbackNumber');
        window.location.href = 'tel:' + phone;
    };
    var suspendedBtnCancel = function () {
        setShowSuspendedCoverageModal(false);
        setShowSuspendedCoverageNonPPUModal(false);
        setSelectedVehicleIndex("");
        setVehicleColor("");
    };
    var suspendedBtnGuest = function () {
        setShowSuspendedCoverageModal(false);
        setContinueToPpu(true);
    };
    var onSelectVehicle = function (index, vehicle) {
        if (vehicle.suspendIndicator === "Y" &&
            partnerDetails.experience.ppuEnabled) {
            setShowSuspendedCoverageModal(true);
        }
        else if (vehicle.suspendIndicator === "Y" &&
            !partnerDetails.experience.ppuEnabled) {
            setShowSuspendedCoverageNonPPUModal(true);
        }
        var vehicleColor = "";
        if (vehicle.color && colorIsSupported(vehicle.color)) {
            vehicleColor = vehicle.color.trim();
        }
        setSelectedVehicleIndex(index);
        setVehicleColor(vehicleColor);
    };
    var onConfirm = function () {
        var _a, _b;
        // on submit save accident question response
        if (isJJ && accidentQuestionResponse) {
            storeAccidentQuestionResponse();
        }
        ;
        var ppuFuelPrice = localStorage.getItem("ppuFuelPrice");
        var coverageFollowsVehicle = commonBenefits.experience.coverageFollowsVehicle;
        var vehicle = cloneDeep(vehicleList[selectedVehicleIndex]);
        if (continueToPpu && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.suspendIndicator) === "Y") {
            dispatch(updatePpuFlag("Y"));
            dispatch(updateLoggedInFlag("N"));
            if (serviceInfo.id === TOW_SERVICE_ID &&
                ((_a = destination === null || destination === void 0 ? void 0 : destination.towInfo) === null || _a === void 0 ? void 0 : _a.totalTowCost) &&
                Number(((_b = destination === null || destination === void 0 ? void 0 : destination.towInfo) === null || _b === void 0 ? void 0 : _b.totalTowCost) > 0) &&
                partnerDetails.experience.milesBased) {
                var totalTowCost = Number(serviceInfo.originalCost) +
                    Number(destination.towInfo.totalTowCost);
                dispatch(updateCCardInfo({ transactionAmount: totalTowCost }));
            }
            else if (serviceInfo.id === TOW_SERVICE_ID &&
                partnerDetails.experience.dollarBased) {
                dispatch(updateServiceTransition({
                    serviceType: serviceInfo.value,
                    declineType: SUSPENDED_VEHICLE_TO_CALL_CENTER,
                }));
                return history.push(generatePath("ServiceTransition", partnerDetails.partnerCode));
            }
            else {
                dispatch(updateCCardInfo({
                    transactionAmount: serviceInfo.originalCost,
                }));
            }
        }
        else if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.suspendIndicator) === "N") {
            if (serviceInfo.id === FUEL_SERVICE_ID && ppuFuelPrice) {
                dispatch(updatePpuFlag("Y"));
                dispatch(updateCCardInfo({ transactionAmount: ppuFuelPrice }));
            }
            else if (serviceInfo.id === TOW_SERVICE_ID &&
                Number(destination.towInfo.totalTowCost) > 0) {
                dispatch(updatePpuFlag("Y"));
                dispatch(updateCCardInfo({
                    transactionAmount: destination.towInfo.totalTowCost,
                }));
            }
            else {
                dispatch(updatePpuFlag("N"));
                dispatch(clearCCardInfo());
            }
            dispatch(updateLoggedInFlag("Y"));
        }
        vehicle.color = vehicleColor ? titleCase(vehicleColor) : "";
        vehicle.equipmentClass = vehicle.equipmentClass || "Light";
        sendVehicleToJacada(vehicle);
        var vehicleBenefitsUsedUp = coverageFollowsVehicle &&
            vehicle.claimCount >= vehicle.maxClaimCount &&
            !vehicle.suspendIndicator;
        if (UNSUPPORTED_EQUIPMENT_CLASSES.includes(vehicle.equipmentClass.toLowerCase())) {
            dispatch(updateServiceTransition({
                declineType: CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS,
            }));
            return history.push(generatePath("ServiceTransition"));
        }
        if (vehicleBenefitsUsedUp) {
            dispatch(updateServiceTransition({
                errorMessageContent: USER_OUT_OF_CLAIMS,
            }));
            return history.push(generatePath("ServiceTransition"));
        }
        if (serviceInfo.id === FUEL_SERVICE_ID && fuelType) {
            dispatch(updateServiceTypeOptions({ gas: fuelType }));
        }
        if (vehicleValue.selectedVehicleIndex !== "" &&
            vehicleValue.selectedVehicleIndex > selectedVehicleIndex) {
            dispatch(updateVehicleInfo({ addedVehicleFlag: false }));
        }
        datadogRum.addAction("Vehicle Information Confirmed");
        dispatch(updateVehicleInfo({
            selectedVehicleIndex: selectedVehicleIndex,
        }));
        if (!!partnerDetails.omniStreamlined && vehicle.promotionCode) {
            datadogRum.addAction("[VehicleSelect] Updating promoCode by vehicle.promotionCode", vehicle);
            dispatch((updatePromoCode(vehicle.promotionCode)));
        }
        else {
            datadogRum.addError("[vehicleSelect] no omniStreamlined or vehicle.promoCode", vehicle);
        }
        confirmVehicleInfo(vehicle);
        history.push(generatePath("location"));
    };
    var confirmVehicleInfo = function (payload) {
        var _a;
        var caranimationtl = new TimelineMax({ paused: true });
        caranimationtl.play(0, false);
        var eventsData = [
            {
                name: "color_name",
                value: payload.color,
            },
        ];
        eventsData.push({
            name: "equpmnt_clss_id",
            value: 1,
        });
        var jacadaRequestData = {
            externalSystemId: localStorage.getItem("conversationId"),
            events: eventsData,
        };
        sendJacadaRequest(jacadaRequestData);
        if (payload.rentalorborrowedflag) {
            var sortedVehiclesByHighestCoverage = cloneDeep(commonBenefits.vehicles);
            sortedVehiclesByHighestCoverage
                .sort(function (a, b) { return a.coverageAmount.localeCompare(b.coverageAmount); })
                .reverse();
            payload.consumervehicleid =
                ((_a = sortedVehiclesByHighestCoverage[0]) === null || _a === void 0 ? void 0 : _a.vehicleID) || "";
        }
        dispatch(updateVehicle(payload));
        if (serviceInfo.id === FUEL_SERVICE_ID && payload.fuelType) {
            dispatch(updateServiceTypeOptions({ gas: payload.fuelType }));
        }
    };
    var selectFuelType = function (event) {
        setFuelType(event.currentTarget.value);
        sendFuelTypeToJacada(event);
    };
    var isButtonEnabled = function () {
        if (selectedVehicleIndex !== undefined && vehicleColor && vehicleColor !== getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)) {
            if (serviceInfo.id === FUEL_SERVICE_ID) {
                return !!fuelType;
            }
            return true;
        }
    };
    var determineFuelAmount = function () {
        var experience = commonBenefits.experience, services = commonBenefits.services;
        var quantity = experience.supportedRegions[0] === "CA"
            ? services === null || services === void 0 ? void 0 : services.filter(function (service) { return service.type === "Gas"; })[0].fuelQtyCan
            : services === null || services === void 0 ? void 0 : services.filter(function (service) { return service.type === "Gas"; })[0].fuelQty;
        return quantity <= 0 ? 2 : quantity;
    };
    var fuelServiceCoverages = (_h = (_g = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.services) === null || _g === void 0 ? void 0 : _g.find(function (service) { return service.type === GAS; })) === null || _h === void 0 ? void 0 : _h.coverages;
    var handleAccidentQuestionChange = function (e) {
        setAccidentQuestionResponse(e.target.value);
    };
    return (React.createElement(Utility.Div, { "data-dd-privacy": "allow", id: "confirm-vehicle-container" },
        React.createElement(ContinueAsPpuModal, { active: showSuspendedCoverageModal, content: getTranslatedText(TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTINUE_PPU), primaryButton: {
                testId: "confirm-vehicle-coverage-suspend-continue",
                value: getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST),
                onClick: suspendedBtnGuest,
            }, closeButton: {
                testId: "confirm-vehicle-coverage-suspend-cancel",
                onClick: suspendedBtnCancel,
            } }),
        React.createElement(ContinueAsPpuModal, { active: showSuspendedCoverageNonPPUModal, content: getTranslatedText(TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE), primaryButton: {
                testId: "confirm-vehicle-coverage-contact-test-call",
                value: getTranslatedText(TRANSLATION_CONSTANTS.CALL),
                onClick: callCallCenterBtn,
            }, closeButton: {
                testId: "confirm-vehicle-contact-rep-cancel",
                onClick: suspendedBtnCancel,
            } }),
        React.createElement(Grid, { id: "vehicleSelect", utils: {
                hrLeft: "md",
                hrRight: "md",
                vrTop: "md",
            }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { col: { sm: 12, md: 4, lg: 4, xl: 4 }, offset: { md: 4, lg: 4, xl: 4 } },
                React.createElement(Grid.Column, null,
                    React.createElement(Flex, { alignItems: "stretch", justifyContent: "between", isBlock: true },
                        React.createElement(Headline, { title: React.createElement(Type, { variant: "brand", align: "left", size: "3xl", weight: "semibold", lineHeight: "md", "data-testid": "confirm-vehicle-title" }, getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_INFORMATION)) }),
                        React.createElement(Utility.Div, { onClick: function () {
                                history.push(generatePath("location"));
                            }, "data-testid": "confirm-vehicle-close-icon" },
                            React.createElement(Icon, { icon: CloseIcon, size: 1.75 })))),
                React.createElement(Divider, { thickness: "thick", utils: {
                        vrBottom: "md",
                    } }),
                !isEmpty(vehicleList) && (React.createElement(Grid.Column, null,
                    React.createElement(FormOptionGroup, { label: getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE), id: "terms", type: "radio", required: true, options: vehicleList.map(function (vehicle, index) {
                            return {
                                id: "radio-".concat(index),
                                name: "terms",
                                text: "".concat(vehicle.year, " ").concat(vehicle.make, " ").concat(vehicle.model),
                                value: "".concat(vehicle.year, " ").concat(vehicle.make, " ").concat(vehicle.model),
                                checked: index === selectedVehicleIndex,
                                "data-testid": "confirm-vehicle-radio-".concat(index),
                                onChange: function () {
                                    onSelectVehicle(index, vehicle);
                                },
                            };
                        }) }))),
                selectedVehicleIndex !== undefined && (React.createElement(Grid.Column, { utils: {
                        vrBottom: "md",
                    } },
                    React.createElement(Dropdown, { defaultOption: {
                            text: "",
                            value: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE),
                        }, id: "color", label: getTranslatedText(TRANSLATION_CONSTANTS.COLOR), name: "color", onChange: function (event) {
                            setVehicleColor(event.target.value);
                        }, value: vehicleColor.toUpperCase(), options: getTranslatedColors(getTranslatedText), required: true, sort: "asc", "data-testid": "confirm-vehicle-color" }))),
                serviceInfo.id !== FUEL_SERVICE_ID && (React.createElement(Divider, { thickness: "thick", utils: {
                        vrBottom: "md",
                    } })),
                serviceInfo.id === FUEL_SERVICE_ID &&
                    selectedVehicleIndex !== undefined && (React.createElement(React.Fragment, null,
                    React.createElement(Grid.Column, null,
                        React.createElement(FormOptionGroup, { label: getTranslatedText(TRANSLATION_CONSTANTS.FUEL_TYPE), id: "helpmechoose", type: "radio", required: true, options: [
                                {
                                    id: "helpmechoose1",
                                    name: "helpmechoose",
                                    text: getTranslatedText(TRANSLATION_CONSTANTS.UNLEADED),
                                    value: "Unleaded",
                                    checked: fuelType === "Unleaded",
                                    "data-testid": "confirm-vehicle-fuel-unleaded",
                                    onChange: function (event) {
                                        selectFuelType(event);
                                    },
                                },
                                {
                                    id: "helpmechoose2",
                                    name: "helpmechoose",
                                    text: getTranslatedText(TRANSLATION_CONSTANTS.DIESEL),
                                    value: "Diesel",
                                    checked: fuelType === "Diesel",
                                    "data-testid": "confirm-vehicle-fuel-diesel",
                                    onChange: function (event) {
                                        selectFuelType(event);
                                    },
                                },
                            ] })),
                    ((_j = commonBenefits.experience) === null || _j === void 0 ? void 0 : _j.fuelCostCovered) && !isEmpty(fuelServiceCoverages) && (React.createElement(Grid.Column, null,
                        React.createElement(FuelAlert, { fuelServiceCoverages: fuelServiceCoverages }))),
                    React.createElement(Grid.Column, null,
                        React.createElement(Divider, { thickness: "thick" })))),
                serviceInfo.id === FUEL_SERVICE_ID &&
                    selectedVehicleIndex !== undefined &&
                    !fuelCostCovered && (React.createElement(Grid.Column, null,
                    React.createElement(Type, { variant: "dark" }, "".concat(getTranslatedText(TRANSLATION_CONSTANTS.DELIVER_UP_TO), " ").concat(determineFuelAmount(), " ").concat(unitOfMeasure, ". ").concat(getTranslatedText(TRANSLATION_CONSTANTS.COST_FUEL_NOT_INCLUDED))))),
                addAVehicle && (React.createElement(Grid.Column, { utils: {
                        vrBottom: "md",
                    } },
                    React.createElement(Button, { size: 'lg', hasUpgrade: true, isHollow: true, shape: "square", onClick: addAnotherVehicle },
                        React.createElement(Icon, { icon: PlusIcon }),
                        " ",
                        React.createElement(Type, { variant: "darker", weight: "bold" }, getTranslatedText(TRANSLATION_CONSTANTS.ADD_VEHICLE)))))),
            isJJ && serviceInfo.id === TOW_SERVICE_ID &&
                React.createElement(Grid.Column, null,
                    React.createElement(AccidentQuestions, { onChange: handleAccidentQuestionChange }))),
        React.createElement(Sticky, { portalContainer: "#confirm-vehicle-container", stickToBody: false, stickToBottom: true },
            React.createElement(Grid, { utils: {
                    noMargin: true,
                } },
                React.createElement(Grid.Column, { col: { sm: 12, md: 4, lg: 4, xl: 4 }, offset: { md: 4, lg: 4, xl: 4 } },
                    React.createElement(Box, { hasBorder: false, utils: {
                            noMargin: true,
                        } },
                        React.createElement(Button, { hasUpgrade: true, shape: "pill", size: "lg", onClick: onConfirm, disabled: !isButtonEnabled(), utils: {
                                fullWidth: true,
                            }, "data-testid": "confirm-vehicle-button" }, lowerCase(getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE)))))))));
};
