export const USER_OUT_OF_CLAIMS = {
  title: "No Benefits Available",
  message: "You’ve used up all of your benefits. We can still help. Contact our representative to request service."
}

export const MEMBER_NOT_FOUND = {
  title: "Sorry, we can't find your benefits information",
  message: "We can still help. Contact our representative to request service."
}

export const BENEFITS_UNAVAILABLE_FOR_SERVICE = {
  title: "We cannot service your request",
  message: "We can still help. Contact our representative to request service."
}

export const UNABLE_TO_PROCESS_REQUEST = {
  title: "Sorry, we're not able to process your request.",
  message: "You may arrange for services independently and then submit a claim for reimbursement consideration."
}

export const CANNOT_BE_SERVICED = {
  title: "Sorry, we cannot service your request in the app",
  message: "We can still help. Contact our representative to request service."
}

export const UNABLE_TO_COMPLETE_SERVICE = {
  title: "We're unable to complete your request in the app",
  message: "We can still help. Contact our representative to request service."
}

export const SERVICE_NOT_AVAILABLE_TEXT = "We are sorry, service is not yet available in this area through this app.";
export const SERVICE_UNAVAILABLE_ID = "choosehelp";
export const SERVICE_UNAVAILABLE_TITLE = "Service is not yet available";

export const INVALID_ADDRESS_TEXT = "Please enter additional details about your current location (i.e. street name, number and ZIP code).";
export const INVALID_ADDRESS_ID = "invalid_address";
export const INVALID_ADDRESS_TITLE = "Invalid Address";

export const GEOLOCATION_DISABLED = "It seems location services are turned off, please enter an address";
