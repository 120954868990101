import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  FETCH_FORM_QUESTIONS,
  FETCH_FORM_QUESTIONS_ERROR,
  FETCH_FORM_QUESTIONS_SUCCESS,
  GET_PREFILLED_DATA,
  GET_PREFILLED_DATA_SUCCESS,
  LOAD_INCIDENT_REPORT_FORM,
  SAVE_FORM_TO_STATE,
  UPDATE_INCIDENT_REPORT_DETAILS,
} from "../action";
import {
  getIsTireDamaged,
  getServiceRequestEmail,
  getServiceFlatTirePart,
  getIncidentReportQuestions as selectIncidentReportQuestions,
} from "../selector";
import { getIncidentReportQuestions } from "../api";
import { datadogRum } from "@datadog/browser-rum";
import { formatDateAsString, formatTimeAsString } from "../utils/timeUtil";
export function* loadIncidentReportFormSaga() {
  yield put({ type: FETCH_FORM_QUESTIONS });
  yield put({ type: GET_PREFILLED_DATA });
}

const getTireChangeReason = (isTireDamaged) => isTireDamaged ? "tire_damage" : "tire_service"
 
export function* fetchIncidentReportFormQuestions() {
  try {
    const isTireDamaged = yield select(getIsTireDamaged)
    const reason = getTireChangeReason(isTireDamaged)
    const payload = yield call(getIncidentReportQuestions, reason);
    yield put({ type: FETCH_FORM_QUESTIONS_SUCCESS, payload });
  } catch (e) {
    datadogRum.addError("fetchIncidentReportFormQuestions Saga error", e);
    yield put({ type: FETCH_FORM_QUESTIONS_ERROR, payload: e });
  }
}

export function* getPrefilledDataSaga() {
  const email = yield select(getServiceRequestEmail);
  const part = yield select(getServiceFlatTirePart);
  const tirePart =
    part === "backLeft" || part === "backRight" ? "Back" : "Front";

  yield put({
    type: GET_PREFILLED_DATA_SUCCESS,
    payload: {
      4710: { name: "email", questionId: 4710, value: email },
      4687: { name: "part", questionId: 4687, value: tirePart },
      4683: { name: "howManyFlatTires", questionId: 4683, value: "One" },
      814: { name: "multipleTires", questionId: 814, value: "N" },
    },
  });
}

export function* saveFormToStateSaga(action) {
  let incidentQnAs = yield select(selectIncidentReportQuestions);
  const answers = action.payload;
  const data = incidentQnAs.map((question) => {
    let responseText;
    if (answers[question.questionId]) {
      const isYesOrNo = question.responseType === "radio_button";
      responseText = !isYesOrNo
        ? answers[question.questionId]
        : answers[question.questionId] == "Yes"
        ? "Y"
        : "N";
      if (question.questionId === 4687 || question.questionId === 4683) {
        responseText = answers[question.questionId].toLowerCase();
      }
      return { ...question, responseText };
    }
  });

  yield put({
    type: UPDATE_INCIDENT_REPORT_DETAILS,
    payload: {
      incidentTime: {
        date: formatDateAsString(),
        time: formatTimeAsString().split(" ")[0],
        meridiem: formatTimeAsString().split(" ")[1].toLowerCase(),
      },
      incidentDetails: data,
    },
  });
}

export function* watchIncidentReportFormQuestionsSaga() {
  yield takeEvery(LOAD_INCIDENT_REPORT_FORM, loadIncidentReportFormSaga);
  yield takeEvery(FETCH_FORM_QUESTIONS, fetchIncidentReportFormQuestions);
  yield takeEvery(GET_PREFILLED_DATA, getPrefilledDataSaga);
  yield takeEvery(SAVE_FORM_TO_STATE, saveFormToStateSaga);
}
