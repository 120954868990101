import _isArray from "lodash/isArray";
import { TOW } from "../app-consts/appConstants"

export const getCommonBenefits = (state) => state.commonBenefits;

export const getCommonBenefitsStatus = (state) =>
  state.commonBenefits?.status?.toUpperCase?.() || "";

export const getCustomerType = (state) => state?.commonBenefits.customerType;

export const getExpirationMileageByIndex = (state, index) => {
  const commonBenefits = getCommonBenefits(state);
  if (
    !commonBenefits?.vehicles ||
    !_isArray(commonBenefits.vehicles) ||
    commonBenefits.vehicles.length <= 0
  ) {
    return false;
  }
  if (!commonBenefits.vehicles[index].expirationMileage) {
    return false;
  }
  return commonBenefits.vehicles[index].expirationMileage;
};

export const getTowCoverages = (state) =>
  state.commonBenefits?.services?.find((service) => service.type === TOW)
    ?.coverages || [];

export const hasBenefitsSelector = (state) =>
  state.partnerDetails.partnerDetails.experience?.benefitsEnabled &&
  state.commonBenefits.benefits;

export const isPayPerUseSelector = (state) =>
  (state.commonBenefits?.noBenefits &&
    state.partnerDetails.partnerDetails.experience?.ppuEnabled) ||
  (state.partnerDetails.partnerDetails.experience?.ppuEnabled &&
    !state.partnerDetails.partnerDetails.experience?.benefitsEnabled);
