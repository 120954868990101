import React, {Component} from 'react';
import {OkayC} from '../../icon-library';
import Icon from '../../components/icon';
import FormField from '../../components/form-field';
import {connect} from 'react-redux';

import {refreshReducersToIntial} from '../../action';
import {generatePath, redirectNavigationUrl} from './../../utilities/RedirectUtils';
import FeedbackStarRating from '../../components/feedback-star-rating';
import {submitFeedback, submitFeedbackSurvey} from "../../api";
import Spinner from "../../components/spinner";
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { LocaleContext } from '../../contexts/LocaleContext';
import { Button } from 'mesh-component-library';
import { datadogRum } from '@datadog/browser-rum';
import { getServiceRequestPayload } from '../../selector';
class Feedback extends Component {
  static contextType = LocaleContext;
  constructor(props) {
    super(props);
    this.state = {
      providerRating: 0,
      appRating: 0,
      additionalComments: ""
    }
  }

  componentDidMount() {
    document.title = 'Feedback | Roadside Assistance';
  }

  showSpinnerModal = () => {
    this.setState({showSpinner: true});
  }

  changeProviderRating = (newRating) => {
    this.setState({providerRating: newRating});
  }

  changeAppRating = (newRating) => {
    this.setState({appRating: newRating});
  }

  updateComment = (event) => {
    this.setState({additionalComments: event.target.value});
  }

  submitFeedbackAndRerouteUser = async () => {
    const {serviceRequestInfo, serviceInfo, partnerDetails, partnerDetails: {partnerDetails: {appId, partnerCode, omniStreamlined = ""}}} = this.props;
    let url = redirectNavigationUrl(partnerDetails.partnerDetails);
    if (!!omniStreamlined) {
      const {
        serviceRequestPayload: { sessionId: callId = "" },
      } = this.props;
      let payload = {
        ratings: [
          { rating: this.state.providerRating, type: "PROVIDER" },
          { rating: this.state.appRating, type: "APP" },
        ],
        comment: this.state.additionalComments,
        requestedBy: "omniassist",
      };
      try {
        await submitFeedbackSurvey(payload, partnerCode, callId);
        this.props.refreshReducersToIntial();
        localStorage.clear();
        localStorage.setItem("feedbackConfirmation", "YES");
      } catch(error) {
        url = 'sitedown';
        datadogRum.addError("Feedback Survey Failed", {partnerCode, usageId, error})
        this.props.refreshReducersToIntial();
        localStorage.clear();
      } finally {
        this.props.history.push(generatePath(url));
        return;
      }
    }
    this.showSpinnerModal();
    let data = {
      actionId: serviceInfo.serviceText,
      ratings: [
        {"rating": this.state.providerRating, "type": "PROVIDER"},
        {"rating": this.state.appRating, "type": "APP"}
      ],
      comments: this.state.additionalComments,
      requestId: serviceRequestInfo.request.requestId,
      sessionId: serviceRequestInfo.request.sessionId,
      providerId: serviceRequestInfo.response.provider?.id,
    };
    let response = await submitFeedback(data, appId, partnerCode);
    if (response.status === 200) {
      datadogRum.addAction(`Feedback submission successful`, {
        displayCode: partnerDetails?.displayCode
      });
      this.props.refreshReducersToIntial();
      localStorage.clear();
      localStorage.setItem('feedbackConfirmation', 'YES');
    } else {
      datadogRum.addAction(`Feedback submission failed`, {
        displayCode: partnerDetails?.displayCode
      });
      url = 'sitedown';
    }
    this.props.history.push(generatePath(url));
  }

  cancelRate = () => {
    const {partnerDetails} = this.props;
    datadogRum.addAction(`User cancelled feedback submission`, {
      displayCode: partnerDetails?.displayCode
    });
    this.props.refreshReducersToIntial();
    localStorage.clear();
    let url = redirectNavigationUrl(partnerDetails.partnerDetails);
    this.props.history.push(generatePath(url));
  }

  render() {
    const {appRating, providerRating, additionalComments, showSpinner} = this.state
    const getTranslatedText = this.context;
    return (
      <div role="main" className="l-container-feedback">
        {showSpinner && <Spinner/>}
        <div className="l-container__header">
          <Icon icon={OkayC} color="green-light" size={3}/>
          <h1>{getTranslatedText(TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED)}</h1>
          <h4>{getTranslatedText(TRANSLATION_CONSTANTS.SHARE_YOUR_FEEBACK)}</h4>
        </div>

        <div className="l-confirmation-container l-confirmation-container_rate">
          <p>{getTranslatedText(TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_RESCUER)}</p>
          <FeedbackStarRating
            rating={providerRating}
            starRatedColor="#0033a0"
            changeRating={this.changeProviderRating}
            numberOfStars={5}
            name='providerRating'
            starDimension="29px"
            hoverMode="false"
            starEmptyColor="#f5f5f5"
          />
          <p className="u-vr-4-top">{getTranslatedText(TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_SITE)}</p>
          <FeedbackStarRating
            rating={appRating}
            starRatedColor="#0033a0"
            changeRating={this.changeAppRating}
            numberOfStars={5}
            name='appRating'
            starDimension="29px"
            starEmptyColor="#f5f5f5"
          />
          <FormField
            label={getTranslatedText(TRANSLATION_CONSTANTS.SHARE_ANY_ADDITIONAL_COMMENTS)}
            showCharCount
            maxLength={150}
            type="textarea"
            rows={4}
            value={additionalComments}
            onChange={this.updateComment}
            className="l-confirmation-container_rate--textarea u-vr-4-top"
          />
          <Button
            size='lg'
            hasUpgrade
            onClick={this.submitFeedbackAndRerouteUser}
            disabled={appRating === 0 || providerRating === 0}
            id="submit-feedback"
            utils={{
              fullWidth: true
            }}>
            submit
          </Button>
          <button className="btn send-request c-cancelBtn"
                  onClick={this.cancelRate}>{getTranslatedText(TRANSLATION_CONSTANTS.SKIP)}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    partnerDetails: state.partnerDetails,
    serviceRequestInfo: state.serviceRequestInfo,
    serviceInfo: state.serviceInfo,
    serviceRequestPayload: getServiceRequestPayload(state),
  };
}

export default connect(mapStateToProps, {
  refreshReducersToIntial,
  submitFeedback
})(Feedback);
