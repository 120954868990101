import React, { useState } from "react";
import FormOption from "../form-option";
import CarTopView from "../svg/CarTopView";
import ButtonGroup from "../button-group/";
import { sendJacadaRequest } from "../../api";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import { Button } from "mesh-component-library";

const JACADA_TIRE_CODES = {
  frontLeft: 3524,
  frontRight: 3525,
  backLeft: 3526,
  backRight: 3527,
};

const TireTriage = ({ partnerCode, updateSoftServiceDetails }) => {
  const [selectedTires, setSelectedTires] = useState([]);
  const [selectedSpare, setSelectedSpare] = useState(undefined);
  const [tireVisiblyDamaged, setTireVisiblyDamaged] = useState(undefined);
  const getTranslatedText = useLocale();
  const isABGPartner =
    partnerCode === "CRA" || partnerCode === "BCA" || partnerCode === "PCR";

  const handleChange = (event) => {
    const val = event.target.value;
    if (val !== "yes" && val !== "no") {
      const index = selectedTires.indexOf(val);
      if (index > -1) {
        // tire already added, remove it
        const tempArr = [...selectedTires];
        tempArr.splice(index, 1);
        setSelectedTires(tempArr);
      } else {
        setSelectedTires([...selectedTires, val]);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const moreThanOneTire = selectedTires.length > 1;
    const towRequired = !selectedSpare || moreThanOneTire;
    const tireChangeOptions = {
      selectedTire: selectedTires[0],
      gotSpare: selectedSpare,
      towReason: "tire_standard_without_spare",
      visiblyDamaged: tireVisiblyDamaged,
    };

    updateSoftServiceDetails(towRequired, "Tire Change", tireChangeOptions);

    let tireSelectionId = JACADA_TIRE_CODES[selectedTires[0]];
    let SVC_DETL_TYPE_ID = 90; //default 1 tire and spare

    if (moreThanOneTire) {
      tireSelectionId = 3528; //multiple tires selection
    }

    const hasFrontTire =
      selectedTires.includes("frontLeft") ||
      selectedTires.includes("frontRight");
    const hasBackTire =
      selectedTires.includes("backLeft") || selectedTires.includes("backRight");

    if (hasFrontTire && !hasBackTire) {
      SVC_DETL_TYPE_ID = 86;
    } else if (hasBackTire && !hasFrontTire) {
      SVC_DETL_TYPE_ID = 92;
    }

    let dataSession = {
      name: "SVC_DETL_TYPE_ID",
      value: SVC_DETL_TYPE_ID,
    };

    let flatTireData = {
      name: "flat_tire_type_id",
      value: tireSelectionId,
    };
    sendJacadaRequest({
      externalSystemId:
        localStorage.getItem("conversationId") || "not available",
      events: [dataSession, flatTireData],
    });
  };

  const moreThanOneFlatTire = selectedTires.length > 1;
  const hasOneFlatTire = selectedTires.length > 0;

  const continueEnabled = !isABGPartner
    ? moreThanOneFlatTire || (hasOneFlatTire && selectedSpare !== undefined)
    : moreThanOneFlatTire ||
      (hasOneFlatTire &&
        selectedSpare !== undefined &&
        tireVisiblyDamaged !== undefined);

  return (
    <form onChange={handleChange}>
      <div className="l-container-triage-tire">
        <div className="c-triage-content-tire">
          <h1 id="which-tire-is-flat">
            {getTranslatedText(TRANSLATION_CONSTANTS.WHICH_TIRE_IS_FLAT)}
          </h1>
          <div className="c-triage-content-selection" data-dd-privacy="allow">
            <div className="c-tire-selection">
              <FormOption
                id="0-front-left"
                type="checkbox"
                value="frontLeft"
                ariaLabel="Front Left Tire"
                name="driverFront"
              />

              <FormOption
                id="1-back-left"
                type="checkbox"
                value="backLeft"
                ariaLabel="Back Left Tire"
                name="passengerFront"
              />
            </div>

            <div className="topViewCar">
              <CarTopView selectedTires={selectedTires} />
            </div>

            <div className="c-tire-selection">
              <FormOption
                id="2-front-right"
                type="checkbox"
                value="frontRight"
                ariaLabel="Front Right Tire"
                name="driverRear"
              />

              <FormOption
                id="3-back-right"
                type="checkbox"
                value="backRight"
                ariaLabel="Back Right Tire"
                name="passengerRear"
              />
            </div>
          </div>
          <div className="c-tire-selection--spare" data-dd-privacy="allow">
            <ButtonGroup
              label={getTranslatedText(TRANSLATION_CONSTANTS.HAVE_A_SPARE_TIRE)}
              type="radio"
              name="spare"
              id="spare"
              disabled={selectedTires.length > 1}
              required={true}
              onChange={(event) =>
                setSelectedSpare(event.target.value === "yes")
              }
              options={[
                {
                  id: "spare-yes",
                  text: getTranslatedText(TRANSLATION_CONSTANTS.YES),
                  value: "yes",
                },
                {
                  id: "spare-no",
                  text: getTranslatedText(TRANSLATION_CONSTANTS.NO),
                  value: "no",
                },
              ]}
            />
          </div>
          {isABGPartner && (
            <div className="c-tire-selection--spare" data-dd-privacy="allow">
              <ButtonGroup
                label={getTranslatedText(
                  TRANSLATION_CONSTANTS.VISIBLY_DAMAGED_TIRE
                )}
                type="radio"
                name="damaged"
                id="damaged"
                disabled={selectedTires.length > 1}
                required={true}
                onChange={(event) =>
                  setTireVisiblyDamaged(event.target.value === "yes")
                }
                options={[
                  {
                    id: "damaged-yes",
                    text: getTranslatedText(TRANSLATION_CONSTANTS.YES),
                    value: "yes",
                  },
                  {
                    id: "damaged-no",
                    text: getTranslatedText(TRANSLATION_CONSTANTS.NO),
                    value: "no",
                  },
                ]}
              />
            </div>
          )}
          <Button
            size="lg"
            hasUpgrade
            id="which-tire-is-flat-continue"
            disabled={!continueEnabled}
            onClick={handleSubmit}
            utils={{
              fullWidth: true,
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default TireTriage;
