import React, { useState, useEffect } from "react";
import { FUEL_SERVICE_ID, GAS, JJ, TOW_SERVICE_ID } from "../../app-consts/appConstants";
import { sendFuelTypeToJacada, sendVehicleToJacada } from "../../jacada/sendJacadaEventData";
import { datadogRum } from "@datadog/browser-rum";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { updateVehicle, updateServiceTypeOptions, updateVehicleInfo, requestMakeModel, resetMakeModel, updateAccidentQuestions } from "../../action";
import { sendJacadaRequest } from '../../api/api';
import { cloneDeep } from 'lodash';
import { TimelineMax } from 'gsap';
import { generatePath } from '../../utilities/RedirectUtils';
import { useLocale } from "../../contexts/LocaleContext";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getServiceInfo, getServiceRequestPayload, getPartnerDetails, getCommonBenefits, getMakemodel } from "../../selector";
import { CloseIcon } from "mesh-icon-library";
import { Button, Divider, Dropdown, Flex, FormOptionGroup, Grid, Headline, Icon, Type, Utility, FormField, Autocomplete, Loader } from "mesh-component-library";
import { getTranslatedColors } from "../../utils/translatedTextUtils";
import { FuelAlert } from "../../components";
import isEmpty from "lodash/isEmpty";
import { AccidentQuestions } from "../../components/AccidentQuestions";
export var AddVehicle = function () {
    var _a, _b, _c, _d;
    var getTranslatedText = useLocale();
    var history = useHistory();
    var dispatch = useDispatch();
    var serviceInfo = useSelector(getServiceInfo);
    var serviceRequestPayload = useSelector(getServiceRequestPayload);
    var partnerDetails = useSelector(getPartnerDetails);
    var commonBenefits = useSelector(getCommonBenefits);
    var isJJ = partnerDetails.partnerCode === JJ;
    var makemodel = useSelector(getMakemodel);
    var vehicle = (serviceRequestPayload === null || serviceRequestPayload === void 0 ? void 0 : serviceRequestPayload.serviceRequest.vehicle) || (serviceRequestPayload === null || serviceRequestPayload === void 0 ? void 0 : serviceRequestPayload.serviceRequest.vehicles);
    var _e = useState(!(vehicle === null || vehicle === void 0 ? void 0 : vehicle.make)), vehicleDisabled = _e[0], setVehicleDisabled = _e[1];
    var _f = useState(false), yearHasError = _f[0], setYearHasError = _f[1];
    var _g = useState(true), reRender = _g[0], setReRender = _g[1];
    var _h = useState((vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) ? "".concat(vehicle === null || vehicle === void 0 ? void 0 : vehicle.make, " ").concat(vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) : ''), vehicleValue = _h[0], setVehicleValue = _h[1];
    var _j = useState((vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) || ''), year = _j[0], setYear = _j[1];
    var _k = useState((vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) || ''), make = _k[0], setMake = _k[1];
    var _l = useState((vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) || ''), model = _l[0], setModel = _l[1];
    var _m = useState((vehicle === null || vehicle === void 0 ? void 0 : vehicle.color) || ''), vehicleColor = _m[0], setVehicleColor = _m[1];
    var _o = useState(((_a = serviceRequestPayload === null || serviceRequestPayload === void 0 ? void 0 : serviceRequestPayload.serviceRequest.serviceTypeOptions) === null || _a === void 0 ? void 0 : _a.gas) || ''), fuelType = _o[0], setFuelType = _o[1];
    var _p = useState(false), showSpinner = _p[0], setShowSpinner = _p[1];
    var _q = useState(undefined), accidentQuestionResponse = _q[0], setAccidentQuestionResponse = _q[1];
    useEffect(function () {
        window.dispatchEvent(new Event("locationchange"));
        if (year) {
            setModelList(year);
        }
    }, []);
    useEffect(function () {
        if (makemodel.payload.length && reRender) {
            setVehicleDisabled(false);
            setReRender(false);
            setShowSpinner(false);
        }
    }, [makemodel.payload.length, reRender]);
    var handleCloseClick = function () {
        history.push(generatePath("location"));
    };
    var storeAccidentQuestionResponse = function () {
        dispatch(updateAccidentQuestions(accidentQuestionResponse));
    };
    var selectFuelType = function (event) {
        setFuelType(event.currentTarget.value);
        sendFuelTypeToJacada(event);
    };
    var setModelList = function (year) {
        dispatch(requestMakeModel({ year: year }));
        setShowSpinner(true);
    };
    var handleAccidentQuestionChange = function (e) {
        setAccidentQuestionResponse(e.target.value);
    };
    var enableVehicle = function (event) {
        var yearCopy = event.target.value;
        setYear(yearCopy);
        var current_year = new Date().getFullYear();
        if (yearCopy.length === 4) {
            if ((yearCopy > 1970) && (yearCopy < current_year + 2)) {
                setYearHasError(false);
                setModelList(yearCopy);
            }
            else {
                setYearHasError(true);
            }
        }
        else {
            if (makemodel.payload.length) {
                dispatch(resetMakeModel());
            }
            setVehicleDisabled(true);
            setVehicleValue('');
            setMake('');
            setModel('');
            setReRender(true);
        }
    };
    var confirmVehicleInfo = function (payload) {
        var _a;
        // on submit save accident question response
        if (isJJ && accidentQuestionResponse) {
            storeAccidentQuestionResponse();
        }
        ;
        var caranimationtl = new TimelineMax({ paused: true });
        caranimationtl.play(0, false);
        var eventsData = [{
                'name': 'color_name',
                'value': payload.color
            }];
        eventsData.push({
            'name': 'equpmnt_clss_id',
            'value': 1
        });
        var jacadaRequestData = {
            'externalSystemId': localStorage.getItem('conversationId'),
            'events': eventsData
        };
        sendJacadaRequest(jacadaRequestData);
        if (payload.rentalorborrowedflag) {
            var sortedVehiclesByHighestCoverage = cloneDeep(commonBenefits.vehicles);
            sortedVehiclesByHighestCoverage.sort(function (a, b) { return a.coverageAmount.localeCompare(b.coverageAmount); }).reverse();
            payload.consumervehicleid = ((_a = sortedVehiclesByHighestCoverage[0]) === null || _a === void 0 ? void 0 : _a.vehicleID) || '';
        }
        dispatch(updateVehicle(payload));
        if (serviceInfo.id === FUEL_SERVICE_ID && payload.fuelType) {
            dispatch(updateServiceTypeOptions({ gas: payload.fuelType }));
        }
        history.push(generatePath('location'));
    };
    var confirmVehicleInformation = function () {
        var vehicleColors = vehicleColor ? vehicleColor.toLowerCase() : "";
        vehicleColors = vehicleColors.charAt(0).toUpperCase() + vehicleColors.slice(1);
        var vehicle = {
            color: vehicleColors,
            make: make,
            model: model,
            year: year,
            equipmentClass: 'Light',
            vin: ''
        };
        if (partnerDetails.partnerCode === JJ) { //TODO revist how partner details is overlaying experience on partner flip
            // @ts-ignore
            vehicle.rentalorborrowedflag = 'Y';
        }
        if (fuelType) {
            dispatch(updateServiceTypeOptions({ gas: fuelType }));
        }
        sendVehicleToJacada(vehicle);
        setReRender(false);
        dispatch(resetMakeModel());
        if (commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.benefits) {
            handleAddedVehicle(true);
        }
        datadogRum.addAction('Redirect to Add Vehicle page');
        confirmVehicleInfo(vehicle);
    };
    var handleAddedVehicle = function (flag) {
        dispatch(updateVehicleInfo({ addedVehicleFlag: flag }));
        updateVehicleIndex(commonBenefits.vehicles.length);
    };
    var updateVehicleIndex = function (index) {
        dispatch(updateVehicleInfo({ selectedVehicleIndex: index }));
    };
    var setMakeModelState = function (event) {
        if (event.target.value.value) {
            var value = event.target.value.value.split("||");
            var make_1 = value[0].replace(/&amp;/g, '&');
            var model_1 = value[1].replace(/&amp;/g, '&');
            setMake(make_1);
            setModel(model_1);
            setVehicleValue("".concat(make_1, " ").concat(model_1));
        }
    };
    var isButtonEnabled = function () {
        // Check if accident question is needed & and answered
        if (isJJ && accidentQuestionResponse === undefined) {
            return false;
        }
        if (year.length === 4 && vehicleColor && make && model && vehicleColor !== getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)) {
            if (serviceInfo.id === FUEL_SERVICE_ID) {
                return !!fuelType;
            }
            return true;
        }
        else {
            return false;
        }
    };
    var endYear = new Date().getFullYear() + 2;
    var getYearErrorText = function (getTranslatedText) { return "".concat(getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_CAR_MODEL), " ").concat(endYear, "."); };
    var vehicleSuggestions = makemodel.payload.map(function (vehicle) { return { 'text': vehicle.text, 'value': "".concat(vehicle.value.make, "||").concat(vehicle.value.model) }; });
    var fuelServiceCoverages = (_c = (_b = commonBenefits === null || commonBenefits === void 0 ? void 0 : commonBenefits.services) === null || _b === void 0 ? void 0 : _b.find(function (service) { return service.type === GAS; })) === null || _c === void 0 ? void 0 : _c.coverages;
    return (React.createElement(Utility.Div, { "data-dd-privacy": "allow" },
        showSpinner &&
            (React.createElement(Utility.Div, { className: "c-overlay has-loader is-fixed c-container-warning" },
                React.createElement(Loader, { "data-testid": "loader", className: "c-loader--xl c-loader--position", size: "xl" }))),
        React.createElement(Grid, { id: "addVehicleScreen", utils: {
                hrLeft: "md",
                hrRight: "md",
                vrTop: "md",
            }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { col: { sm: 12, md: 4, lg: 4, xl: 4 }, offset: { md: 4, lg: 4, xl: 4 } },
                React.createElement(Grid.Column, null,
                    React.createElement(Flex, { alignItems: "stretch", justifyContent: "between", isBlock: true },
                        React.createElement(Headline, { title: React.createElement(Type, { variant: "brand", align: "left", size: "3xl", weight: "semibold", lineHeight: "md", "data-testid": "add-vehicle-title" }, getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE_INFO)) }),
                        React.createElement(Utility.Div, { onClick: handleCloseClick, "data-testid": "add-vehicle-close-icon" },
                            React.createElement(Icon, { icon: CloseIcon, size: 1.75 })))),
                React.createElement(Divider, { thickness: "thick", utils: {
                        vrBottom: "md",
                    } }),
                React.createElement(Grid.Column, { utils: { vrBottom: 'md' } },
                    React.createElement(FormField, { hasError: yearHasError, errorText: getYearErrorText(getTranslatedText), placeholder: getTranslatedText(TRANSLATION_CONSTANTS.YYYYY), name: "year", id: "year", label: getTranslatedText(TRANSLATION_CONSTANTS.YEAR), type: "tel", maxLength: 4, value: year, onChange: function (event) {
                            enableVehicle(event);
                        }, "data-testid": "add-vehicle-year" })),
                React.createElement(Grid.Column, { utils: { vrBottom: 'md' } },
                    React.createElement(Autocomplete, { placeholder: "", id: "vehicleinfo", label: getTranslatedText(TRANSLATION_CONSTANTS.MAKE_MODEL), value: vehicleValue === null || vehicleValue === void 0 ? void 0 : vehicleValue.replace("||", " "), suggestions: vehicleSuggestions, disabled: vehicleDisabled, onChange: function (event) {
                            setMakeModelState(event);
                        }, required: true, "data-testid": "add-vehicle-autocomplete" })),
                React.createElement(Grid.Column, null,
                    React.createElement(Dropdown, { id: "color", name: "color", label: getTranslatedText(TRANSLATION_CONSTANTS.COLOR), value: vehicleColor.toUpperCase(), defaultOption: {
                            text: "",
                            value: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE),
                        }, options: getTranslatedColors(getTranslatedText), onChange: function (event) {
                            setVehicleColor(event.target.value);
                        }, required: true, sort: "asc", "data-testid": "add-vehicle-color" })),
                serviceInfo.id === FUEL_SERVICE_ID && (React.createElement(React.Fragment, null,
                    React.createElement(Grid.Column, { util: { vrTop: 'md' } },
                        React.createElement(FormOptionGroup, { label: getTranslatedText(TRANSLATION_CONSTANTS.FUEL_TYPE), id: "helpmechoose", type: "radio", required: true, options: [
                                {
                                    id: "helpmechoose1",
                                    name: "helpmechoose",
                                    text: getTranslatedText(TRANSLATION_CONSTANTS.UNLEADED),
                                    value: "Unleaded",
                                    checked: fuelType === "Unleaded",
                                    "data-testid": "add-vehicle-fuel-unleaded",
                                    onChange: function (event) {
                                        selectFuelType(event);
                                    }
                                },
                                {
                                    id: "helpmechoose2",
                                    name: "helpmechoose",
                                    text: getTranslatedText(TRANSLATION_CONSTANTS.DIESEL),
                                    value: "Diesel",
                                    checked: fuelType === "Diesel",
                                    "data-testid": "add-vehicle-fuel-diesel",
                                    onChange: function (event) {
                                        selectFuelType(event);
                                    }
                                },
                            ] })),
                    ((_d = commonBenefits.experience) === null || _d === void 0 ? void 0 : _d.fuelCostCovered) && !isEmpty(fuelServiceCoverages) && (React.createElement(Grid.Column, null,
                        React.createElement(FuelAlert, { fuelServiceCoverages: fuelServiceCoverages }))))),
                isJJ && serviceInfo.id === TOW_SERVICE_ID && (React.createElement(Grid.Column, { utils: {
                        vrBottom: "md",
                        vrTop: "lg",
                    } },
                    React.createElement(AccidentQuestions, { onChange: handleAccidentQuestionChange }))),
                React.createElement(Grid.Column, { utils: {
                        vrBottom: "md",
                        vrTop: "lg"
                    } },
                    React.createElement(Button, { hasUpgrade: true, shape: "pill", size: "lg", onClick: confirmVehicleInformation, disabled: !isButtonEnabled(), utils: {
                            fullWidth: true,
                        }, id: "add-vehicle-button", "data-testid": "add-vehicle-button" }, getTranslatedText(TRANSLATION_CONSTANTS.ADD)))))));
};
