export var getAccidentQuestionResponse = function (state) {
    return state.accidentQuestions.customerResponse;
};
export var getServiceRequestEmail = function (state) {
    return (state === null || state === void 0 ? void 0 : state.serviceRequestPayload.serviceRequest.email) || "";
};
export var getServiceFlatTirePart = function (state) {
    var _a, _b;
    return ((_b = (_a = state === null || state === void 0 ? void 0 : state.serviceRequestPayload.serviceRequest) === null || _a === void 0 ? void 0 : _a.serviceTypeOptions) === null || _b === void 0 ? void 0 : _b.tire.replacementTire) || "";
};
export var getIsTireDamaged = function (state) {
    var _a, _b, _c, _d;
    return (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.serviceRequestPayload) === null || _a === void 0 ? void 0 : _a.serviceRequest) === null || _b === void 0 ? void 0 : _b.serviceTypeOptions) === null || _c === void 0 ? void 0 : _c.tire) === null || _d === void 0 ? void 0 : _d.visiblyDamaged;
};
