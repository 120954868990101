import { connect } from "react-redux";
import { IncidentReportForm } from "./IncidentReportForm";
import { loadIncidentReportPage, saveFormToState } from "../../action";
import {
  getCustomerType,
  getIncidentReportQuestions,
  getPrefilledData,
} from "../../selector";

const mapStateToProps = (state) => ({
  customerType: getCustomerType(state),
  prefilledData: getPrefilledData(state),
  questions: getIncidentReportQuestions(state),
});

export const IncidentReportFormContainer = connect(mapStateToProps, {
  onCompleteForm: saveFormToState,
  onInit: loadIncidentReportPage,
})(IncidentReportForm);
