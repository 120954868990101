var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { REFRESH_REDUCERS, UPDATE_ACCIDENT_QUESTIONS } from "../action";
var initialState = {
    customerResponse: undefined,
};
export var AccidentQuestionReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UPDATE_ACCIDENT_QUESTIONS:
            return __assign(__assign({}, state), { customerResponse: action.payload });
        case REFRESH_REDUCERS:
            return __assign({}, initialState);
        default:
            return state;
    }
};
