import React from "react";
import { FormOptionGroup } from "mesh-component-library";
export var AccidentQuestions = function (_a) {
    var onChange = _a.onChange, response = _a.response;
    return (React.createElement(FormOptionGroup, { label: "Was this vehicle involved in an accident?", onChange: onChange, options: [
            {
                id: "accident-option-1",
                name: "accident-options",
                text: "Yes",
                value: "Yes",
                defaultChecked: "Yes" === response,
            },
            {
                id: "accident-option-2",
                name: "accident-options",
                text: "No",
                value: "No",
                defaultChecked: "No" === response,
            },
        ], type: "radio", required: true }));
};
