import React from "react";
import { ButtonGroup } from "mesh-component-library";

export const YesNoButtonGroup = ({
  onChange,
  name,
  label,
  value,
  disabled,
}) => {
  const options = [
    {
      text: "Yes",
      value: "Yes",
      checked: (value === "Yes" || value === "Y"),
      onChange,
    },
    {
      text: "No",
      value: "No",
      checked: (value === "No" || value === "N"),
      onChange,
    },
  ];

  return (
    <ButtonGroup
      label={label}
      options={options}
      required={true}
      name={name}
      disabled={disabled}
    />
  );
};
