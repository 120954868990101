import React, {useEffect} from 'react';
import Header from '../../components/header/index'
import Footer from '../../components/footer/index';
import PartnerBrand from '../partnerBranding';
import {useDispatch, useSelector} from "react-redux";
import {
  getPartnerDetails,
  getServiceInfo,
} from "../../selector";
import {requestPartnerDetails} from "../../action";

const DefaultLayout = ({ children, isRescueTrackerStandalone, hideMenu }) => {
  const dispatch = useDispatch();
  const partnerDetails = useSelector(getPartnerDetails);

  useEffect(() => {
    if (isRescueTrackerStandalone) {
      dispatch(requestPartnerDetails(window.location.pathname.split("/")[3]));
    }
  }, []);

  const serviceInfo = useSelector(getServiceInfo);

  const {
    hasAllstateBrandHeader,
    hasAllstateBrandFooter,
    phone,
    displayCode,
    isFooterBrandingEnabled,
    isHeaderBrandingEnabled,
    isHeaderDisabled,
    isFooterDisabled,
    displayFeedbackModal
  } = partnerDetails;

  const currentPage = window.location.pathname.split("/")[3];
  const hidePartnerBrandAndFooter = ["location","disablementlocation","payment","triage-steps","confirm-vehicle", "add-vehicle", "incident-report"].includes(currentPage);
  const isAllstateBranded = hasAllstateBrandFooter && hasAllstateBrandHeader
  const isNoBranding = !(isHeaderBrandingEnabled || isFooterBrandingEnabled)
  const shouldDisplayPartnerBrand = !(isAllstateBranded || hidePartnerBrandAndFooter || isNoBranding || isHeaderDisabled)

  return (
      <div>
        <Header
          hasAllstateBrandHeader={hasAllstateBrandHeader}
          isHeaderBrandingEnabled={isHeaderBrandingEnabled}
          isHeaderDisabled={isHeaderDisabled}
          phone={phone}
          displayFeedbackModal={displayFeedbackModal}
          displayCode={displayCode}
          serviceInfo={serviceInfo}
          hideMenuButton={isRescueTrackerStandalone || hideMenu}/>
          {shouldDisplayPartnerBrand ? <PartnerBrand isRescueTrackerStandalone={isRescueTrackerStandalone}/> : null}
          { children }
          {hidePartnerBrandAndFooter || isFooterDisabled ? null : <Footer isRescueTrackerStandalone={isRescueTrackerStandalone} hasAllstateBrandFooter={hasAllstateBrandFooter} isFooterBrandingEnabled={isFooterBrandingEnabled} />}
      </div>
    )
};

export default DefaultLayout;
