import React from "react";
import { FormField } from "mesh-component-library";


/** See all available props here: https://mesh-component-library.apps.csts-k8cnp-ro-1.r-caas.allstate.com/?path=/docs/forms-formfield--docs&build=m47focn0 */
export const FormFieldV2 = ({
  ...props
}) => {
  return (
    <FormField
      {...props}
    />
  );
};
