import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useQuery from "../hooks/useQuery";
import { getServiceRequestPayload } from "../selector";
import { refreshReducersToIntial } from "../action";
import { generatePath } from "../utilities/RedirectUtils";


const withSession = (WrappedComponent) => {
  const WithSession = (props) => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { session = "" } = useQuery();
    const { sessionId = "" } = useSelector(getServiceRequestPayload);
    const dispatch = useDispatch();

    useEffect(() => {
      if (session && sessionId && session !== sessionId) {
        localStorage.clear();
        dispatch(refreshReducersToIntial());
        history.push(generatePath("lookup"));
      }
      setLoading(false);
    }, []);

    return !loading && <WrappedComponent {...props} />;
  };

  return WithSession;
};

export default withSession;
