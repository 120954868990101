import React from "react";

export const ReportIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    id="report-animation-svg"
  >
    <g className="reportAnimation">
      <g className="IncidentReport">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 0C5.37274 0 0 5.37274 0 12C0 18.6273 5.37274 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37274 18.6273 0 12 0Z"
          fill="#B9C6D3"
        />
        <path
          d="M17.2969 8.2959V17.5508C17.2969 18.3857 16.6201 19.0625 15.7852 19.0625H8.26172C7.42676 19.0625 6.75 18.3857 6.75 17.5508V8.2959C6.75 7.46094 7.42676 6.78418 8.26172 6.78418H8.44629V17.3574H15.5918V6.78418H15.7764C16.6113 6.78418 17.2881 7.46094 17.2881 8.2959H17.2969ZM10.0371 8.51562H10.6963H13.3506H14.0098C14.3789 8.51562 14.6689 8.2168 14.6689 7.85645V6.97754C14.6689 6.6084 14.3701 6.31836 14.0098 6.31836H13.3506C13.3506 5.58887 12.7529 5 12.0234 5C11.2939 5 10.6963 5.58887 10.6963 6.31836H10.0371C9.66797 6.31836 9.37793 6.61719 9.37793 6.97754V7.85645C9.37793 8.22559 9.67676 8.51562 10.0371 8.51562Z"
          fill="white"
        />
        <path
          d="M17 7.40503V17.78C17 18.47 16.44 19.03 15.75 19.03H8.25C7.56 19.03 7 18.47 7 17.78V7.40503C7 6.71503 7.56 6.15503 8.25 6.15503H8.7525C8.7525 6.17503 8.75 6.19753 8.75 6.21753V6.65503H8.25C7.8375 6.65503 7.5 6.99253 7.5 7.40503V17.78C7.5 18.1925 7.8375 18.53 8.25 18.53H15.75C16.1625 18.53 16.5 18.1925 16.5 17.78V7.40503C16.5 6.99253 16.1625 6.65503 15.75 6.65503H15.25V6.21753C15.25 6.19753 15.25 6.17503 15.2475 6.15503H15.75C16.44 6.15503 17 6.71503 17 7.40503ZM14.75 6.21753V6.71753C14.75 6.99253 14.525 7.21753 14.25 7.21753H9.75C9.475 7.21753 9.25 6.99253 9.25 6.71753V6.21753C9.25 5.94253 9.475 5.71753 9.75 5.71753H10.625V5.46753C10.625 5.19253 10.85 4.96753 11.125 4.96753H12.875C13.15 4.96753 13.375 5.19253 13.375 5.46753V5.71753H14.25C14.525 5.71753 14.75 5.94253 14.75 6.21753ZM12.375 5.96753C12.375 5.76003 12.2075 5.59253 12 5.59253C11.7925 5.59253 11.625 5.76003 11.625 5.96753C11.625 6.17503 11.7925 6.34253 12 6.34253C12.2075 6.34253 12.375 6.17503 12.375 5.96753Z"
          fill="white"
        />
        <path
          d="M14.75 9.28003H9.25C9.1125 9.28003 9 9.16753 9 9.03003C9 8.89253 9.1125 8.78003 9.25 8.78003H14.75C14.8875 8.78003 15 8.89253 15 9.03003C15 9.16753 14.8875 9.28003 14.75 9.28003ZM15 10.78C15 10.6425 14.8875 10.53 14.75 10.53H9.25C9.1125 10.53 9 10.6425 9 10.78C9 10.9175 9.1125 11.03 9.25 11.03H14.75C14.8875 11.03 15 10.9175 15 10.78ZM15 12.53C15 12.3925 14.8875 12.28 14.75 12.28H9.25C9.1125 12.28 9 12.3925 9 12.53C9 12.6675 9.1125 12.78 9.25 12.78H14.75C14.8875 12.78 15 12.6675 15 12.53ZM15 16.03C15 15.8925 14.8875 15.78 14.75 15.78H9.25C9.1125 15.78 9 15.8925 9 16.03C9 16.1675 9.1125 16.28 9.25 16.28H14.75C14.8875 16.28 15 16.1675 15 16.03ZM15 14.28C15 14.1425 14.8875 14.03 14.75 14.03H9.25C9.1125 14.03 9 14.1425 9 14.28C9 14.4175 9.1125 14.53 9.25 14.53H14.75C14.8875 14.53 15 14.4175 15 14.28Z"
          fill="white"
        />
      </g>
      <g className="TickThree" fill="#95D600">
        <path
          id="06007okayC"
          d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_910_4085">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
