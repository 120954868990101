export const FETCH_FORM_QUESTIONS = "FETCH_FORM_QUESTIONS";
export const FETCH_FORM_QUESTIONS_SUCCESS = "FETCH_FORM_QUESTIONS_SUCCESS";
export const FETCH_FORM_QUESTIONS_ERROR = "FETCH_FORM_QUESTIONS_ERROR";
export const SAVE_FORM_TO_STATE = "SAVE_FORM_TO_STATE";

export const GET_PREFILLED_DATA = "GET_PREFILLED_DATA";
export const GET_PREFILLED_DATA_SUCCESS = "GET_PREFILLED_DATA_SUCCESS";
export const LOAD_INCIDENT_REPORT_FORM = "LOAD_INCIDENT_REPORT_FORM";

export const loadIncidentReportPage = () => ({
  type: LOAD_INCIDENT_REPORT_FORM,
});

export const fetchFormQuestions = () => ({
  type: FETCH_FORM_QUESTIONS,
});

export const fetchFormQuestionsSuccess = (data) => ({
  type: FETCH_FORM_QUESTIONS_SUCCESS,
  payload: data,
});

export const fetchFormQuestionsError = (data) => ({
  type: FETCH_FORM_QUESTIONS_ERROR,
  payload: data,
});

export const saveFormToState = (data) => ({
  type: SAVE_FORM_TO_STATE,
  payload: data,
});

export const getPrefilledData = () => ({
  type: GET_PREFILLED_DATA,
});

export const getPrefilledDataSuccess = () => ({
  type: GET_PREFILLED_DATA_SUCCESS,
});
