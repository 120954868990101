import React from "react";
import { Alert, Type } from "mesh-component-library";
import { CANADA_COUNTRY_CODE, en_CA, GALLON, LITER, USA_COUNTRY_CODE, } from "../../app-consts/appConstants";
import { determineLocale, useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
export var FuelAlert = function (_a) {
    var fuelServiceCoverages = _a.fuelServiceCoverages;
    var getTranslatedText = useLocale();
    var locale = determineLocale();
    var status = "";
    var quantity = 0;
    var unit = "";
    var coverage = {};
    if (locale === en_CA) {
        coverage = fuelServiceCoverages === null || fuelServiceCoverages === void 0 ? void 0 : fuelServiceCoverages.find(function (coverage) { return (coverage === null || coverage === void 0 ? void 0 : coverage.country) === CANADA_COUNTRY_CODE; });
        status = coverage === null || coverage === void 0 ? void 0 : coverage.status;
        quantity = coverage === null || coverage === void 0 ? void 0 : coverage.quantity;
        unit = LITER;
    }
    else {
        coverage = fuelServiceCoverages === null || fuelServiceCoverages === void 0 ? void 0 : fuelServiceCoverages.find(function (coverage) { return (coverage === null || coverage === void 0 ? void 0 : coverage.country) === USA_COUNTRY_CODE; });
        status = coverage === null || coverage === void 0 ? void 0 : coverage.status;
        quantity = coverage === null || coverage === void 0 ? void 0 : coverage.quantity;
        unit = GALLON;
    }
    return (React.createElement(Alert, { variant: "information", size: "md", title: React.createElement(Type, { align: "left" }, "You'll receive ".concat(quantity ? quantity : 2, " ").concat(unit).concat(quantity && quantity < 2 ? "" : "s", " of fuel.")), children: React.createElement(Type, { align: "left" }, (status === null || status === void 0 ? void 0 : status.toLowerCase()) === "covered"
            ? getTranslatedText(TRANSLATION_CONSTANTS.VAS_FUEL_COVERED)
            : getTranslatedText(TRANSLATION_CONSTANTS.VAS_FUEL_NOT_COVERED)) }));
};
