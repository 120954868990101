import React, {useState} from "react";
import FormOption from '../form-option';
import ModalDialog from '../modal-dialog';
import Loader from '../../components/loader';
import {generatePath, generateRedirectPathForOmniPartners, redirectNavigationUrl} from './../../utilities/RedirectUtils';
import {
  cancelRescueApi,
  cancelViaRescueTracker,
  sendJacadaRequest
} from "../../api/api";
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {
  allowLettersNumbersAndDashes,
  allowNumbers
} from "../../utils/regexUtil";
import {useSelector} from "react-redux";
import {getPartnerDetails} from "../../selector";
import { Button } from 'mesh-component-library';
export const CancellationModal = (props) => {
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const [cancelReasonText,setCancelReasonText] = useState("")
  const partnerDetails = useSelector(getPartnerDetails)
  const getTranslatedText = useLocale()

  const cancelService = async () => {
    setShowSpinner(true)
    const {usageId, requestId, sessionId, cancellationSource:source = "",scheduled = false} = props;
    const {partnerCode, displayCode, appId, omniStreamlined = ""} = partnerDetails;
    if(props.useAlternateCancellationEndpoint){
      const sanitizedUsageId = allowNumbers(usageId)
      const cancelResponse = await cancelViaRescueTracker(displayCode, sanitizedUsageId, cancelReasonText, source, scheduled)
      props.hideCancelModal()
      if (!!omniStreamlined && props.isSMSRescueTracker) {
        redirectPageForOmniPartners(partnerDetails, cancelResponse?.status, props, displayCode);
      } else if(cancelResponse?.status=== "OK"){
        props.setRescueCancelled(true)
        props.clearDuplicateRescue()
      } else {
        props.setShowCancellationError(true)
      }
    } else {
      let eventsData = [{
        'name': 'cancelReason',
        'value': cancelReasonText
      }];
      let jacadaRequestData = {
        'externalSystemId': localStorage.getItem('conversationId'),
        'events': eventsData
      }
      sendJacadaRequest(jacadaRequestData);

      const sanitizedRequestId = allowNumbers(requestId)
      const sanitizedSessionId = allowLettersNumbersAndDashes(sessionId)

      let response = await cancelRescueApi(appId, partnerCode, sanitizedRequestId, sanitizedSessionId, cancelReasonText, source)

      let url = redirectNavigationUrl(partnerDetails);
      if (response.data.status === "OK") {
        localStorage.clear()
        localStorage.setItem('rescueCancel', 'YES');
        localStorage.setItem('isPpu', props.ppuFlag === 'Y' ? 'true' : 'false')
      } else {
        url = 'sitedown';
      }
      props.refreshReducersToInitial();
      props.history.push(generatePath(url));
    }
  }

  const enableButtonCancelReason = (event) => {
     setCancelReasonText(event.currentTarget.defaultValue);
     setCancelButtonDisabled(false);
   }

  const displaySpinner = () => {
    return showSpinner ?
      <div className="c-overlay has-loader is-fixed">
        <Loader className="c-loader--xl c-loader--position"/>
      </div>
      :
      null
  }

    return (
      <div className='cancel-rescue-modal'>
        {window.scrollTo(0, 0)}
        {displaySpinner()}
        <ModalDialog
          id="cancellationModal"
          title={getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_CONFIRMATION_HEADER)}
          hideTrigger
          showCloseBtn={true}
          isActive={true}
          onClose={props.hideCancelModal}
          footer={
            <Button
            size='lg'
            hasUpgrade
            id="cancel-button"
            disabled={cancelButtonDisabled}
            onClick={cancelService}
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_BUTTON_TEXT)}
          </Button>
                          }>
          <div className="c-fuelType" data-dd-privacy="allow">
            <div className="heading-2">
              <label>{getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_BODY)}</label>
            </div>
            <FormOption
              onClick={(event) => {enableButtonCancelReason(event)}}
              onKeyDown={(event) => {enableButtonCancelReason(event)}}
              label={getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1)}
              value="Service no longer needed"
              name="cancelReason"
              type="radio"/>
            <br/>
            <FormOption
              onClick={(event) => {enableButtonCancelReason(event)}}
              onKeyDown={(event) => {enableButtonCancelReason(event)}}
              label={getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2)}
              value="Unacceptable ETA"
              name="cancelReason"
              type="radio"/>
            <br/>
            <FormOption
              onClick={(event) => {enableButtonCancelReason(event)}}
              onKeyDown={(event) => {enableButtonCancelReason(event)}}
              label={getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3)}
              value="Other"
              name="cancelReason"
              type="radio"/>
          </div>
        </ModalDialog>
      </div>
    )
  }

export const redirectPageForOmniPartners = (partnerDetails, responseStatus, props, displayCode) => {
  let url = redirectNavigationUrl(partnerDetails, partnerDetails?.route);
  if (responseStatus === "OK") {
    localStorage.clear();
    localStorage.setItem('rescueCancel', 'YES');
    localStorage.setItem('isPpu', props.ppuFlag === 'Y' ? 'true' : 'false');
  } else {
    url = 'sitedown';
  }
  props.refreshReducersToInitial();
  props.updatePartnerDetailsPartnerCode();
  props.updatePartnerDetailsDisplayCode();
  props.updatePartnerDetailsRoute();
  props.history.push(generateRedirectPathForOmniPartners(partnerDetails, displayCode, url));
};
