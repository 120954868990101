import React from "react";
import Alert from "../alert";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";

export const OvercostWarning = (props) => {
  return (
    <div className="c-container-warning">
      <Alert type="warning">
        <b>After using the full amount of your roadside coverage for this service,</b>
        <p>you will owe ${props.amount}. You’ll need to pay this amount directly to your rescuer when they arrive.</p>
      </Alert>
    </div>
  )
}

const primaryLookupMessage = (noBenefitsLookType) => {
  switch(noBenefitsLookType) {
    case 'firstNameLastNameZip':
      return TRANSLATION_CONSTANTS.FN_LN_ZIP_LOOKUP_FAILURE;
    case 'LastNameStateZip':
      return TRANSLATION_CONSTANTS.NAME_STATE_ZIP_LOOKUP_FAILURE;
    case 'phoneNumber':
    case 'phoneNumberLastNameLookup':
      return TRANSLATION_CONSTANTS.PHONE_LOOKUP_FAILURE;
    default:
      return "";
  }
}

export const PrimaryLookupFailure  = ({noBenefitsLookType = ""}) => {
  const getTranslatedText = useLocale();
  const warningMessage = primaryLookupMessage(noBenefitsLookType);
  return !!warningMessage && (
    <div id="primary-lookup-failure-message" className="c-container-warning">
      <Alert type="warning">
        <b>{getTranslatedText(warningMessage)}</b>
      </Alert>
    </div>
  );
}
