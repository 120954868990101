export const iconToCheckAnimation = (
  carAnimation,
  creditCardAnimation,
  reportAnimation
) => {
  carAnimation
    .to(".Car", 0.2, {
      opacity: 0,
      scaleY: 0.5,
      scaleX: 0.5,
      delay: 0.7,
      transformOrigin: "50% 50%",
    })
    .from(".Tick", 1, {
      opacity: 0,
      scaleY: 0.1,
      scaleX: 0.1,
      transformOrigin: "50% 50%",
      ease: "Elastic.easeOut",
    });
  carAnimation.timeScale(0.8);

  creditCardAnimation
    .to(".CreditCard", 0.2, {
      opacity: 0,
      scaleY: 0.5,
      scaleX: 0.5,
      delay: 0.7,
      transformOrigin: "50% 50%",
    })
    .from(".TickTwo", 1, {
      opacity: 0,
      scaleY: 0.1,
      scaleX: 0.1,
      transformOrigin: "50% 50%",
      ease: "Elastic.easeOut",
    });
  creditCardAnimation.timeScale(0.8);

  reportAnimation
    .to(".IncidentReport", 0.2, {
      opacity: 0,
      scaleY: 0.5,
      scaleX: 0.5,
      delay: 0.7,
      transformOrigin: "50% 50%",
    })
    .from(".TickThree", 1, {
      opacity: 0,
      scaleY: 0.1,
      scaleX: 0.1,
      transformOrigin: "50% 50%",
      ease: "Elastic.easeOut",
    });
  reportAnimation.timeScale(0.8);
};
