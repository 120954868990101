import React from 'react';
import BlueLogo from '../../assets/bluelogo.svg'
import SigLogo from '../../assets/sig-logo.svg';
import {
  determineIfTheLanguageIsEnglish,
  useLocale
} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

const Footer = ({ hasAllstateBrandFooter, isFooterBrandingEnabled, isRescueTrackerStandalone }) => {
  const getTranslatedText = useLocale();
  const showNoticeAtCollection = !isRescueTrackerStandalone || determineIfTheLanguageIsEnglish(window.location.pathname, window.location.search);
  const links =
    <>
      <a className="c-link" href="https://www.roadsidemobile.com/privacy">{getTranslatedText(TRANSLATION_CONSTANTS.PRIVACY_POLICY)}</a> |
      <a className="c-link" href={getTranslatedText(TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION_LINK)}> {getTranslatedText(TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION)}</a> |
      {showNoticeAtCollection ? <><a className="c-link" href="https://roadsidemobile.com/tac/privacystatement#info-we-collect"> {getTranslatedText(TRANSLATION_CONSTANTS.NOTICE_AT_COLLECTION)}</a> | </> : null}
      <a className="c-link" href="https://roadsidemobile.com/tac/accessibility"> {getTranslatedText(TRANSLATION_CONSTANTS.WEBSITE_ACCESSIBILITY)}</a>
    </>

  const imgSrc = hasAllstateBrandFooter ? BlueLogo : SigLogo;
  const altText = hasAllstateBrandFooter ? getTranslatedText(TRANSLATION_CONSTANTS.FOOTER_COMPANY_SLOGAN) : getTranslatedText(TRANSLATION_CONSTANTS.FOOTER_COMPANY_SIGNATURE_CLUB);
  const footerText = hasAllstateBrandFooter ? getTranslatedText(TRANSLATION_CONSTANTS.ALLSTATE_FOOTER_TEXT) : getTranslatedText(TRANSLATION_CONSTANTS.FOOTER_COMPANY_SIGNATURE_CLUB);

  return (
    <footer className={`footer ${ hasAllstateBrandFooter ? '' : 'smc u-vr-5-top'}`}>
      { isFooterBrandingEnabled ? <img src={ imgSrc } alt={ altText } /> : null }
        <p className={ hasAllstateBrandFooter ? '' : 'u-vr-3-top'}>&copy; {new Date().getFullYear()} {footerText}</p>
        <p>{getTranslatedText(TRANSLATION_CONSTANTS.ALL_RIGHT_RESERVED)}</p>
      { links }
    </footer>
  );
};

export default Footer;
