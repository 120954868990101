
import {TRANSLATION_CONSTANTS} from "../../../app-consts/translations";
import { english_translation } from "../translation_en";

export const english_translation_pep = {
    ...english_translation,
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]: "Sorry. Can't find an area Pep Boys. Please call customer service.",
    [TRANSLATION_CONSTANTS.PEP_BOYS_FLEET]: "Are you a Pep Boys Fleet customer?",
    [TRANSLATION_CONSTANTS.PEP_BANNER]: "Final payment for tows will need to be paid  at the Pep Boys repair shop after your rescue. Payment for all other services will be collected at the time of submission.",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]: "Since your key is lost or broken, we can tow your vehicle to a Pep Boys repair shop.",
    [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]: "We can arrange a tow to have a Pep Boys service center unlock your trunk to retrieve your keys.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]: "We can tow your vehicle to a Pep Boys repair shop for assistance since you’ll need an additional tire.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]: "Since your vehicle stopped while driving, your battery most likely isn't the problem. Instead, we can tow your vehicle to a Pep Boys repair shop.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_TITLE]: "Maintain your vehicle and budget",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_CONTENT]: "See if you prequalify for a Pep Boys Credit Card with NO IMPACT to your credit score.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_PREQUALIFY]: "Prequalify",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "continue to tow",
    [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]: "unlock my vehicle",
}
